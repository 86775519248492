import styled from "styled-components";

export const Container = styled.div`
    grid-area: AS; // Aside

    border-width: 2px;
    border-color: #828282;
    border-right-style: solid;
    position: relative;

    > ul {
        > li {
            color: ${(props) => props.theme.colors.labelText};
        }
    }

    &.closed {
        width: 117px;
        transition: width 0.3s ease;

        > ul {
            > li {
                a,
                .option-parent {
                    div {
                        justify-content: center;
                    }
                }

                svg {
                    height: 54px;
                    /* transform: scale(2.2); */
                    width: 40px;
                    transition: width 0.3s ease;
                }

                span {
                    display: none;
                }

                /* &.closed-menu-mark {
                    background: var(--gray5);
                } */
            }
        }
    }
    &.open {
        width: 230px;
        transition: width 0.3s ease;

        > ul {
            padding: 16px 7px;

            > li {
                padding-left: 8px;

                svg {
                    height: 54px;
                    /* transform: scale(1.2); */
                    width: 20px;
                    transition: width 0.3s ease;
                }

                span {
                    display: unset;
                }
            }
        }
    }

    @media (max-width: 576px) {
        display: none;
    }
`;

export const Puller = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;

    position: absolute;
    width: 50px;
    height: 60px;
    top: 45vh;
    right: -50px;

    border: 2px solid #828282;
    border-radius: 0 10px 10px 0;

    svg {
        font-size: 40px;
        color: ${(props) => props.theme.colors.titleText};
    }
`;

export const Options = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    row-gap: 16px;
    font-weight: 700 !important;

    > li {
        display: flex;
        flex-direction: column;
        align-self: center;
        min-width: 100%;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        a,
        .option-parent {
            color: ${(props) => props.theme.colors.menuElements} !important;
            font-weight: bold;
            width: 100%;
            text-decoration: none;

            &:hover {
                color: ${(props) =>
                    props.theme.id === 1
                        ? "var(--primary-variation4) !important"
                        : "inherit !important"};
                // Hover igual ao hover dos botões
                ${(props) =>
                    props.theme.id !== 1 ? "opacity: 0.8 !important" : ""};
            }

            > div {
                display: flex;
                flex-direction: row;
                width: 100%;
                column-gap: 8px;
                /* background: green; */

                span {
                    white-space: pre;
                    align-self: center;
                }
            }
        }

        svg {
            height: 54px;
            width: 20px;
        }

        > ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-top: 8px;
            height: auto;

            &.closed {
                /* display: none; */
                height: 0;
                overflow: hidden;
                /* background: red; */
            }

            > li {
                align-items: center;

                a {
                    text-decoration: none;
                    font-weight: normal;

                    &.submenu-active {
                        font-weight: 700 !important;
                    }
                }
            }
        }
    }

    .active {
        color: ${(props) => (props.theme.id === 1 ? "#5b0c0f" : "inherit")};
    }
`;
