import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, FormGroup, Buttons } from "./styles";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import Button from "../../components/Button";
import InputSelectWrapper from "../../components/InputSelectWrapper";

const courseModules = [
    { label: "Módulo 1", value: "1" },
    { label: "Módulo 2", value: "2" },
    { label: "Módulo 3", value: "3" }
];

const types = [
    { label: "Envio de Arquivos", value: "Anexo" },
    { label: "Orientação", value: "Orientação" },
    { label: "Questionário", value: "Questionário" },
    { label: "Teste Online", value: "Teste Online" },
    { label: "Wiki", value: "Wiki" },
    { label: "Encontro", value: "Encontro" }
];

const inputSelectStyle = {
    control: (provided: React.CSSProperties) => ({
        ...provided,
        borderRadius: "2px",
        border: "2px solid var(--secondary)"
    }),
    placeholder: (provided: React.CSSProperties) => ({
        ...provided,
        color: "#141414"
    })
};

const NewActivity = () => {
    const [selectedModule, setSelectedModule] = useState<string>("");
    const [selectedType, setSelectedType] = useState<string>("");
    const navigate = useNavigate();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;

    const handleModuleChange = (value: string) => {
        setSelectedModule(value);
    };

    const handleTypeChange = (value: string) => {
        setSelectedType(value);
    };

    const handleSubmit = () => {
        console.log("Módulo:", selectedModule);
        console.log("Tipo:", selectedType);
        // TODO: Implementar lógica
    };

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}/resources/activities`);
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Banco de Recursos</span>
                <span>Recursos</span>
            </Breadcrumb>
            <Title>Inserir</Title>
            <FormGroup>
                <InputSelectWrapper
                    style={{ width: "100%" }}
                    label="Módulo: "
                    id="module"
                    name="module"
                    placeholder="Selecione o Módulo..."
                    value={selectedModule}
                    options={courseModules}
                    selectStyles={inputSelectStyle}
                    onChange={handleModuleChange}
                />
            </FormGroup>
            <FormGroup>
                <InputSelectWrapper
                    style={{ width: "100%", marginTop: "0" }}
                    label="Tipo: "
                    id="type"
                    name="type"
                    placeholder="Selecione o Tipo..."
                    value={selectedType}
                    options={types}
                    selectStyles={inputSelectStyle}
                    onChange={handleTypeChange}
                />
            </FormGroup>
            <Buttons style={{ justifyContent: "center" }}>
                <Button type="submit" btnTheme="primary" onClick={handleSubmit}>
                    Salvar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

export default NewActivity;
