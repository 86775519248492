import React from "react";
import ActivityPage from "../ActivityPage";

const Documents = () => {
    return (
        <ActivityPage
            activityType="Envio de Arquivos"
            dataKey="documents"
            user="Patrícia Zudio"
            announcement="Você ainda tem que fazer o teste online!"
        />
    );
};

export default Documents;
