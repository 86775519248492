import api from './api';
import { Module } from '../models/course/Module';
import { IStepFour } from '../models/course/Course';

const ENDPOINT = 'curso_modulos';

export const courseModuleService = {
    listCourseModules,
    editCourseModules,
    create,
    edit,
    remove,
    removeList,
};

async function listCourseModules(courseCode: number): Promise<IStepFour> {
    const { data } = await api.get<any>(`${ENDPOINT}/passo/${courseCode}`);

    return data;
};

async function editCourseModules(module: IStepFour, courseCode: number): Promise<IStepFour> {
    const { data } = await api.put<any>(`${ENDPOINT}/passo/${courseCode}`, module);

    return data;
};

async function create(courseCode: number, module: Module): Promise<any> {
    const { data } = await api.post<any>(`${ENDPOINT}/${courseCode}`, module);

    return data;
};

async function edit(module: Module, courseCode: number): Promise<any> {
    const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, module);

    return data;
};

async function remove(courseCode: number, modulesId: number): Promise<any> {
    const { data } = await api.delete<any>(`${ENDPOINT}/${courseCode}/${modulesId}`);

    return data;
};

async function removeList(courseCode: number, moduleIds: number[]): Promise<any> {
    const { data } = await api.delete(`${ENDPOINT}/${courseCode}`, {
        data: moduleIds
    });

    return data;
};