import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    FaTimes as IconClose,
    FaBars as IconOpen,
    FaSearch as IconSearch,
    FaCalendarAlt as IconSchedule,
    FaChalkboardTeacher,
    FaPencilRuler,
    FaServer,
    FaUserAlt,
    FaUserCog,
    FaAsterisk,
    FaPuzzlePiece,
    FaSatelliteDish,
    FaEye,
    FaListUl,
    FaBox,
    FaUsers,
    FaSdCard
    // FaUserLock
} from "react-icons/fa";
import brand from "../../../assets/img/logo_c.jpg";
import brandHighContrast from "../../../assets/img/logo_c_branco.png";
import { useMenu } from "../../../shared/contexts/MenuContext";
import { useUser } from "../../../shared/contexts/UserContext";
import { useAuth } from "../../../shared/contexts/Auth";
import { useAccessibility } from "../../../shared/contexts/AccessibilityContext";
import {
    Container,
    Options,
    Puller,
    LinkBrand,
    NavButton,
    Buttons,
    OptionsWrap
} from "./styles";
import { useCourse } from "../../../shared/contexts/CourseContext";
import { Module } from "../../../shared/models/course/Module";

const MenuMobile: React.FC = () => {
    const { menu, trackedOption, openOrCloseMenu, expanderManagementOption } =
        useMenu();
    const { hasCompleteRegistration } = useUser();
    const { signOut, hasPermission } = useAuth();
    const { theme } = useAccessibility();
    const location = useLocation();
    const navigate = useNavigate();

    const currentUrl = location.pathname;
    const courseCodeText = currentUrl.replace("/courseenvironment/", "");
    const courseCode = Number.parseInt(courseCodeText);

    const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
    const [modules, setModules] = useState<Module[]>([]);
    const { fetchStepFour, dataStepFour } = useCourse();

    const isModulesFetchedRef = useRef(false);

    useEffect(() => {
        const fetchModules = async () => {
            if (courseCode && !isModulesFetchedRef.current) {
                await fetchStepFour(courseCode);
                isModulesFetchedRef.current = true;
            }
        };
        fetchModules();
    }, [courseCode, fetchStepFour]);

    useEffect(() => {
        if (dataStepFour && dataStepFour.modulos) {
            setModules(dataStepFour.modulos);
        }
    }, [dataStepFour]);

    const handleClick = useCallback(() => {
        if (menu.isOpen) {
            setOpenSubmenu(null); // Fecha todos os submenus abertos
        }
        openOrCloseMenu();
    }, [menu.isOpen, openOrCloseMenu]);

    const handleSubmenuClick = useCallback(
        (submenu: string) => {
            if (!menu.isOpen) {
                openOrCloseMenu(); // Expande o menu se estiver retraído
                setOpenSubmenu(submenu); // E expande o submenu
            } else {
                if (openSubmenu === submenu) {
                    // Se o submenu clicado já estiver aberto, recolha todo o menu
                    setOpenSubmenu(null);
                    openOrCloseMenu();
                } else {
                    // Se outro submenu estiver aberto, feche-o e abra somente o submenu atual
                    setOpenSubmenu(submenu);
                }
            }
        },
        [menu.isOpen, openOrCloseMenu, openSubmenu]
    );

    const handleModuleClick = useCallback(
        (module: Module) => {
            setOpenSubmenu(null);
            // Navega para a página do módulo específico. O estado
            // passado para a navegação inclui informações adicionais
            // sobre o módulo, garanting que a página seja renderizada
            // corretamente sem depender da URL.
            navigate(`/courseenvironment/${courseCode}/module/${module.id}`, {
                state: {
                    courseId: courseCode,
                    moduleId: module.id,
                    moduleName: module.nome,
                    moduleDescription: module.descricao
                }
            });
        },
        [courseCode, navigate]
    );

    const handleLogoffClick = () => {
        openOrCloseMenu();
        signOut();
    };

    const getLogoImage = () => (theme.id !== 2 ? brand : brandHighContrast);

    return (
        <Container className="aux-menu-mobile">
            <Buttons>
                <LinkBrand>
                    <Link to="/" title="Minha Constructore">
                        <img src={getLogoImage()} alt="Logo Constructore" />
                    </Link>
                </LinkBrand>
                <NavButton>
                    <Link to="#" title="Busca">
                        <IconSearch />
                    </Link>
                </NavButton>
                <NavButton>
                    <Link to="#" title="Calendário">
                        <IconSchedule />
                    </Link>
                </NavButton>
                <Puller onClick={handleClick}>
                    {menu.isOpen ? <IconClose /> : <IconOpen />}
                </Puller>
            </Buttons>
            <OptionsWrap className={menu.isOpen ? "open" : "closed"}>
                {location.pathname?.includes("courseenvironment") ? (
                    <Options aria-label="Menu Ambiente de Curso">
                        <li className={trackedOption === 1 ? "active" : ""}>
                            <Link
                                id="first"
                                to={`/courseenvironment/${courseCode}`}
                                title="Apresentação"
                            >
                                <div>
                                    <FaAsterisk />
                                    <span>Apresentação</span>
                                </div>
                            </Link>
                        </li>
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 2 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Módulos"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("modules");
                                    }}
                                >
                                    <div>
                                        <FaPuzzlePiece />
                                        <span>Módulos</span>
                                    </div>
                                </Link>
                                {openSubmenu === "modules" &&
                                    modules.length > 0 && (
                                        <ul>
                                            {modules.map((module) => (
                                                <li key={module.id}>
                                                    <Link
                                                        to="#"
                                                        title={module.nome}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleModuleClick(
                                                                module
                                                            );
                                                        }}
                                                    >
                                                        {module.nome}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 3 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Comunicação"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("communication");
                                    }}
                                >
                                    <div>
                                        <FaSatelliteDish />
                                        <span>Comunicação</span>
                                    </div>
                                </Link>
                                {openSubmenu === "communication" && (
                                    <ul>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/communication/announcements`}
                                                title="Avisos"
                                            >
                                                Avisos
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/communication/forum`}
                                                title="Fórum"
                                            >
                                                Fórum
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 4 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Consulta"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("query");
                                    }}
                                >
                                    <div>
                                        <FaEye />
                                        <span>Consulta</span>
                                    </div>
                                </Link>
                                {openSubmenu === "query" && (
                                    <ul>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/query/glossary`}
                                                title="Glossário"
                                            >
                                                Glossário
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/query/bibliography`}
                                                title="Bibliografia"
                                            >
                                                Bibliografia
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 5 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Banco de Recursos"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("resources");
                                    }}
                                >
                                    <div>
                                        <FaListUl />
                                        <span>Banco de Recursos</span>
                                    </div>
                                </Link>
                                {openSubmenu === "resources" && (
                                    <ul>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/resources`}
                                                title="Recursos"
                                            >
                                                Recursos
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/resources/activities`}
                                                title="Atividades"
                                            >
                                                Atividades
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 6 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Gestão do Curso"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("");
                                    }}
                                >
                                    <div>
                                        <FaBox />
                                        <span>Gestão do Curso</span>
                                    </div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 7 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Participantes"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("participants");
                                    }}
                                >
                                    <div>
                                        <FaUsers />
                                        <span>Participantes</span>
                                    </div>
                                </Link>
                                {openSubmenu === "participants" && (
                                    <ul>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/participants/students`}
                                                title="Alunos"
                                            >
                                                Alunos
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/participants/collaborators`}
                                                title="Colaboradores"
                                            >
                                                Colaboradores
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/participants/teacher`}
                                                title="Professor"
                                            >
                                                Professor
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 8 ? "active" : ""}>
                                <Link
                                    to="#"
                                    title="Histórico"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmenuClick("history");
                                    }}
                                >
                                    <div>
                                        <FaSdCard />
                                        <span>Histórico</span>
                                    </div>
                                </Link>
                                {openSubmenu === "history" && (
                                    <ul>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/transcript?view=transcript`}
                                                title="Boletim"
                                            >
                                                Boletim
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/courseenvironment/${courseCode}/transcript?view=participation`}
                                                title="Participação"
                                            >
                                                Participação
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                        {/* {hasCompleteRegistration && ( */}
                        {/*     <li className={trackedOption === 9 ? "active" : ""}> */}
                        {/*         <Link */}
                        {/*             to="#" */}
                        {/*             title="MenuOpcao9" */}
                        {/*             onClick={(e) => { */}
                        {/*                 e.preventDefault(); */}
                        {/*                 handleSubmenuClick(""); */}
                        {/*             }} */}
                        {/*         > */}
                        {/*             <div> */}
                        {/*                 <FaUserLock /> */}
                        {/*                 <span>Menu Opcao 9</span> */}
                        {/*             </div> */}
                        {/*         </Link> */}
                        {/*     </li> */}
                        {/* )} */}
                    </Options>
                ) : (
                    <Options>
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 1 ? "active" : ""}>
                                <Link to="/mycourses" title="Meus cursos">
                                    <div>
                                        <FaChalkboardTeacher />
                                        <span>Meus cursos</span>
                                    </div>
                                </Link>
                            </li>
                        )}
                        <li className={trackedOption === 2 ? "active" : ""}>
                            <Link to="/mydata" title="Meus dados">
                                <div>
                                    <FaUserAlt />
                                    <span>Meus dados</span>
                                </div>
                            </Link>
                        </li>
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 3 ? "active" : ""}>
                                <Link to="#" title="Agenda">
                                    <div>
                                        <IconSchedule />
                                        <span>Agenda</span>
                                    </div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && hasPermission() && (
                            <li className={trackedOption === 4 ? "active" : ""}>
                                <Link
                                    to="/course"
                                    title="Criar curso"
                                    reloadDocument={true}
                                >
                                    <div>
                                        <FaPencilRuler />
                                        <span>Criar curso</span>
                                    </div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption === 5 ? "active" : ""}>
                                <Link to="#" title="Recursos">
                                    <div>
                                        <FaServer />
                                        <span>Recursos</span>
                                    </div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li>
                                <div
                                    className={`option-parent ${
                                        trackedOption === 6 ? "active" : ""
                                    }`}
                                    title="Administração"
                                    onClick={(e) => expanderManagementOption()}
                                >
                                    <div>
                                        <FaUserCog />
                                        <span>Administração</span>
                                    </div>
                                </div>
                                <ul
                                    className={`option-parent-list ${
                                        !menu.options.isOpenManagement ||
                                        !menu.isOpen
                                            ? "closed"
                                            : ""
                                    }`}
                                >
                                    <li>Cursos</li>
                                    <li>
                                        <Link to="/Admin" title="Administração">
                                            <div>
                                                <span>Usuários</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>Layout</li>
                                    <li>Estatística</li>
                                    <li>Grupos de áreas de conhecimento</li>
                                    <li>Perguntas de inscrição</li>
                                    <li>Suporte técnico</li>
                                </ul>
                            </li>
                        )}
                        <li>
                            <button onClick={handleLogoffClick}>Sair</button>
                        </li>
                    </Options>
                )}
            </OptionsWrap>
        </Container>
    );
};

export default MenuMobile;
