import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaTrash, FaPlus } from "react-icons/fa";
import { useFormik } from "formik";
import {
    Container,
    FormContainer,
    FormRow,
    FormGroup,
    Label,
    Input,
    Buttons
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import InputMasked from "../../../components/InputMasked";
import InputSelect from "../../../components/InputSelect";

interface LocationState {
    participantName?: string;
    participantCpf?: string;
    participantDre?: string;
}

interface ParticipantsEditProps {
    mode: "new" | "edit";
}

interface FormData {
    name: string;
    cpf: string;
    dre: string;
    role?: string;
}

const ParticipantsEdit: React.FC<ParticipantsEditProps> = ({ mode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const currentUrl = location.pathname;
    const participantsAttribute = currentUrl.replace(/^.*participants\//, "");

    const state = location.state as LocationState;

    const [formContainers, setFormContainers] = useState<FormData[]>([
        { name: state?.participantName || "", cpf: state?.participantCpf || "", dre: state?.participantDre || "", role: "" }
    ]);

    useEffect(() => {
        if (state?.participantName || state?.participantCpf || state?.participantDre) {
            setFormContainers([{ name: state.participantName || "", cpf: state.participantCpf || "", dre: state.participantDre || "", role: "" }]);
        }
    }, [state]);

    const handleAddFormContainer = () => {
        setFormContainers([...formContainers, { name: "", cpf: "", dre: "", role: "" }]);
    };

    const handleInputChange = (index: number, field: keyof FormData, value: string) => {
        const updatedFormContainers = [...formContainers];
        updatedFormContainers[index] = {
            ...updatedFormContainers[index],
            [field]: value,
        };
        setFormContainers(updatedFormContainers);
    };

    const handleRemoveFormContainer = (index: number) => {
        const updatedFormContainers = formContainers.filter((_, i) => i !== index);
        setFormContainers(updatedFormContainers);
    };

    const handleSave = () => {
        console.log("Dados a serem salvos:", formContainers);

        fetch("/api/saveParticipants", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ participants: formContainers }),
        }).then(() => {
            navigate(-1);
        });
    };

    const formik = useFormik({
        onSubmit: handleSave,
        enableReinitialize: true,
        initialValues: formContainers,
    });

    const getTitle = () => {
        if (participantsAttribute === 'students/new') {
            return 'Inserir Alunos';
        }

        if (participantsAttribute === 'students/edit') {
            return 'Editar Alunos';
        }

        if (participantsAttribute === 'collaborators/new') {
            return 'Inserir Colaboradores';
        }

        if (participantsAttribute === 'collaborators/edit') {
            return 'Editar Colaboradores';
        }
    };

    const getBreadcrumb = () => {
        if (participantsAttribute === 'students/new') {
            return 'Alunos > Inserir Participantes';
        }

        if (participantsAttribute === 'students/edit') {
            return 'Alunos > Editar Participantes';
        }

        if (participantsAttribute === 'collaborators/new') {
            return 'Colaboradores > Inserir Participantes';
        }

        if (participantsAttribute === 'collaborators/edit') {
            return 'Colaboradores > Editar Participantes';
        }
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Participantes</span>
                <span>{getBreadcrumb()}</span>
            </Breadcrumb>
            <Title>Participantes</Title>
            <Title>{getTitle()}</Title>

            {participantsAttribute === `collaborators/edit/${id}` || participantsAttribute === 'collaborators/new' ? (
                formContainers.map((formData, index) => (
                    <FormContainer key={`collaborator-${index}`}>
                        <FormGroup>
                            <Label htmlFor={`participant-name-${index}`}>Nome:</Label>
                            <Input
                                id={`participant-name-${index}`}
                                type="text"
                                value={formData.name}
                                onChange={(e) =>
                                    handleInputChange(index, "name", e.target.value)
                                }
                                placeholder="Insira o Nome..."
                            />
                        </FormGroup>

                        <FormRow>
                            <FormGroup style={{ maxWidth: "300px" }}>
                                <Label htmlFor={`participant-role-${index}`}>Função*:</Label>
                                <InputSelect
                                    name="role"
                                    options={[
                                        { value: '1', label: 'Monitor(a)' },
                                        { value: '2', label: 'Orador(a)' },
                                        { value: '3', label: 'Mentor(a)' },
                                    ]}
                                    onChange={(e) =>
                                        handleInputChange(index, "role", e.target.value)
                                    }
                                />
                            </FormGroup>
                            <Button btnTheme="link" onClick={() => handleRemoveFormContainer(index)}>
                                <FaTrash /> Excluir
                            </Button>
                        </FormRow>
                    </FormContainer>
                ))
            ) : (
                formContainers.map((formData, index) => (
                    <FormContainer key={`student-${index}`}>
                        <FormGroup>
                            <Label htmlFor={`participant-name-${index}`}>Nome:</Label>
                            <Input
                                id={`participant-name-${index}`}
                                type="text"
                                value={formData.name}
                                onChange={(e) =>
                                    handleInputChange(index, "name", e.target.value)
                                }
                                placeholder="Insira o Nome..."
                            />
                        </FormGroup>

                        <FormGroup style={{ maxWidth: "300px" }}>
                            <Label htmlFor={`participant-cpf-${index}`}>CPF*:</Label>
                            <InputMasked
                                maskType="cpf"
                                id={`participant-cpf-${index}`}
                                name={`participant-cpf-${index}`}
                                placeholder="000.000.000-00"
                                value={formData.cpf}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleInputChange(index, "cpf", e.target.value)
                                }
                            />
                        </FormGroup>

                        <FormRow>
                            <FormGroup style={{ maxWidth: "300px" }}>
                                <Label htmlFor={`participant-dre-${index}`}>DRE*:</Label>
                                <InputMasked
                                    maskType="dre"
                                    id={`participant-dre-${index}`}
                                    name={`participant-dre-${index}`}
                                    placeholder="00000.000-00"
                                    value={formData.dre}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputChange(index, "dre", e.target.value)
                                    }
                                />
                            </FormGroup>
                            <Button btnTheme="link" onClick={() => handleRemoveFormContainer(index)}>
                                <FaTrash /> Excluir
                            </Button>
                        </FormRow>
                    </FormContainer>
                ))
            )}

            {participantsAttribute === 'students/new' && (
                <Buttons style={{ display: "flex", justifyContent: "center" }}>
                    <Button btnTheme="link" onClick={handleAddFormContainer}>
                        <FaPlus /> Aluno
                    </Button>
                </Buttons>
            )}

            {participantsAttribute === 'collaborators/new' && (
                <Buttons style={{ display: "flex", justifyContent: "center" }}>
                    <Button btnTheme="link" onClick={handleAddFormContainer}>
                        <FaPlus /> Colaborador
                    </Button>
                </Buttons>
            )}
        </Container>
    );
};

export default ParticipantsEdit;
