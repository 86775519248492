import React from "react";
import ActivityPage from "../ActivityPage";

const Wiki = () => {
    return (
        <ActivityPage
            activityType="Wiki"
            dataKey="wikis"
            user="Patrícia Zudio"
            announcement="Você ainda tem que fazer o teste online!"
        />
    );
};

export default Wiki;
