// Formata datas usando o seguinte padrão: <b>DD.MM.YYYY</b> às HH:MMh

import React from "react";
import { DatePart } from "./styles";

interface FormattedDateProps {
    date: string | Date;
    bold?: boolean;
}

const FormattedDateBr: React.FC<FormattedDateProps> = ({ date, bold = false }) => {
    const formatDate = new Date(date).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    return <span>{formatDate}</span>;
};

export default FormattedDateBr;




