import React from "react";
import Select, { SingleValue } from "react-select";
import { Wrapper, Label } from "./styles";
import { SelectStyles } from "../../assets/styles/SelectStyles";
import { DomainSelectOption } from "../../shared/models/domains/Domains";

interface InputSelectProps {
    label: string;
    id: string;
    name: string;
    placeholder: string;
    value?: string;
    options: DomainSelectOption[];
    onChange?: (option: string) => void;
    fullWidth?: boolean;
    style?: React.CSSProperties;
    selectStyles?: any;
}

const InputSelectWrapper: React.FC<InputSelectProps> = ({
    label,
    id,
    name,
    placeholder,
    value,
    options,
    onChange,
    fullWidth = false,
    style,
    selectStyles = {}
}) => {
    const handleChange = (selectedOption: SingleValue<{ label: string; value: string }>) => {
        if (onChange && selectedOption) {
            onChange(selectedOption.value);
        }
    };

    return (
        <Wrapper fullWidth={fullWidth} style={style}>
            <Label htmlFor={id}>{label}</Label>
            <Select
                inputId={id}
                name={name}
                options={options}
                placeholder={placeholder}
                value={options.find(option => option.value === value) || null}
                onChange={handleChange}
                styles={{ ...SelectStyles, ...selectStyles }}
            />
        </Wrapper>
    );
};

export default InputSelectWrapper;
