import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: all 200ms;
    align-self: flex-end;
    flex-direction: column;
    /* margin-bottom: 4px; */
    align-self: flex-start;

    label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    textarea {
        width: 100%;
        font-size: inherit;
        background: #fff;
        min-height: 32px;
        border: 1px solid #949494;
        // padding: 8px 16px;
        padding: 8px;
        resize: none;

        &:disabled {
            background: var(--gray6);
        }

        // FIXME: O correto aqui seria color: ${(props) =>
            props.theme.colors
                .placeholderText}; levando em conta os dois temas, mas as cores não batem
        &::placeholder {
            color: var(--labelText);
            padding: 0;
        }
    }

    small {
        margin-top: 2px;
        align-self: start;
        text-align: left;
        color: red;
        min-height: 15px;
    }
`;
