import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcrumb";
import ProgressBar from "../../components/ProgressBar";
import { mockResources } from "./mockResources";
import { toast } from "react-toastify";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useAccessibility } from "../../shared/contexts/AccessibilityContext";
import {
    Container,
    SubTitle,
    ButtonsContainer,
    ButtonGroup,
    ButtonWrapper,
    DetailsContainer,
    DetailRow,
    DetailLabel,
    DetailValue,
    AttachmentsWrapper,
    ProgressBarWrapper,
    AnnouncementContainer,
    Announcement
} from "./styles";

const ResourcePage = () => {
    const { resourceId } = useParams<{ resourceId: string }>();
    const resource = mockResources.find(
        (item) => item.id === parseInt(resourceId || "", 10)
    );
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;
    const currentUsage = 47.6;
    const totalUsage = 100;
    const [selectedResourceId] = useState<number | null>(null);
    const navigate = useNavigate(); // useNavigate para navegação

    const { theme } = useAccessibility();
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    const handleEditClick = () => {
        if (resourceId !== null) {
            console.log(resourceId);
            const selectedResource = mockResources.find(
                (item) => item.id === parseInt(resourceId || "", 10)
            );

            if (selectedResource) {
                navigate(
                    `/courseenvironment/${courseId}/resources/edit/${resourceId}`,
                    {
                        state: {
                            resource: selectedResource
                        }
                    }
                );
            }
        } else {
            toast.error("Por favor, selecione um recurso.", {
                theme: theme.id !== 2 ? "light" : "dark"
            });
        }
    };

    const handleDeleteClick = () => {
        console.log(`Recurso ${selectedResourceId} excluído!`);
    };

    const handleDeleteConfirm = () => {
        openConfirmDialog({
            text: "Você tem certeza que deseja excluir este recurso?",
            title: "Excluir recurso",
            onConfirm: handleDeleteClick
        });
    };

    const handleButtonClick = (action: () => void) => {
        if (selectedResourceId === null) {
            toast.error("Por favor, selecione um recurso.", {
                theme: theme.id !== 2 ? "light" : "dark"
            });
        } else {
            action();
        }
    };

    const buttons = [
        {
            label: "Editar",
            onClick: handleEditClick
        },
        {
            label: "Excluir",
            onClick: () => handleButtonClick(handleDeleteConfirm)
        },
        { label: "Download" }
    ];

    if (!resource) {
        return <div>Recurso não encontrado</div>;
    }

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Banco de Recursos</span>
                <span>Recursos</span>
            </Breadcrumb>

            <Title>Recursos</Title>
            <SubTitle>{resource.title}</SubTitle>

            <ConfirmDialog />

            <ButtonsContainer>
                {/* Wrap somente quando o número de botões na
                horizontal for quatro ou mais */}
                <ButtonGroup style={{ flexWrap: "nowrap" }}>
                    {buttons.map((button, index) => (
                        <ButtonWrapper key={index}>
                            <Button btnTheme="primary" onClick={button.onClick}>
                                {button.label}
                            </Button>
                        </ButtonWrapper>
                    ))}
                </ButtonGroup>
            </ButtonsContainer>
            <DetailsContainer>
                <DetailRow>
                    <DetailLabel>Descrição:</DetailLabel>
                    <DetailValue>{resource.description}</DetailValue>
                </DetailRow>
                <DetailRow>
                    <DetailLabel>Tipo:</DetailLabel>
                    <DetailValue>{resource.type}</DetailValue>
                </DetailRow>
                <DetailRow>
                    <DetailLabel>Associação:</DetailLabel>
                    {/* FIXME: */}
                    <DetailValue>
                        {resource.courses &&
                            resource.courses.length > 0 && (
                                <ul>
                                    {resource.courses.map((curso) => (
                                        <li key={typeof curso === "object" && curso !== null ? curso.code : curso}>
                                            <a
                                                href={`/courseenvironment/${typeof curso === "object" && curso !== null ? curso.code : "#"}`}
                                            >
                                                {typeof curso === "string" ? curso : curso.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                    </DetailValue>
                </DetailRow>
                <DetailRow>
                    <DetailLabel>Inserido Por:</DetailLabel>
                    <DetailValue>{resource.AddedBy}</DetailValue>
                </DetailRow>
                <DetailRow>
                    <DetailLabel>Inserido Em:</DetailLabel>
                    <DetailValue>{resource.AddedOn}</DetailValue>
                </DetailRow>
            </DetailsContainer>
            <AnnouncementContainer>
                <Announcement>
                    <span>Aviso!</span> <span>{resource.announcement}</span>
                </Announcement>
            </AnnouncementContainer>
            <AttachmentsWrapper>
                <div style={{ fontWeight: "700", marginTop: "2em" }}>
                    Anexos:
                </div>
                <ProgressBarWrapper style={{ marginTop: "0" }}>
                    <ProgressBar
                        currentUsage={currentUsage}
                        totalUsage={totalUsage}
                    />
                </ProgressBarWrapper>
            </AttachmentsWrapper>

            {/* A legenda é o nome do arquivo?  */}
            <FileList>
                {resource.attachments &&
                    resource.attachments.map((attachment, index) => (
                        <FileItem key={attachment}>
                            <FileNumber>{index + 1}.</FileNumber>
                            {attachment}
                        </FileItem>
                    ))}
            </FileList>
        </Container>
    );
};

const FileList = styled.ul`
    margin-top: 30px;
    list-style: none;
`;

const FileItem = styled.li`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: var(--primary);
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
`;

const FileNumber = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

export default ResourcePage;
