import styled from "styled-components";

export const Container = styled.div`
    grid-area: MH; // Main Header

    justify-self: center;
    display: flex;
    justify-content: space-between;
    border-width: 2px;
    border-color: #828282;
    border-bottom-style: solid;
    /* width: 100vw; */
    width: 100%;
    height: 154px;
    padding: 33px 24px;

    > div:first-child {
        display: flex;
        flex-direction: column;

        div:first-child {
            width: 714px;
            align-self: center;
        }
    }

    .input-search {
        input {
            background-color: ${(props) => props.theme.colors.bgPrimary};
            color: ${(props) => props.theme.colors.labelText};
            font-size: 1rem;

            &::placeholder {
                color: ${(props) => props.theme.colors.placeholderSearchText};
            }
        }
    }

    @media (max-width: 576px) {
        display: none;
    }
`;

export const Links = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 32px;
    font-size: 1rem;

    a {
        padding: 8px 16px;
        white-space: pre;
        color: ${(props) =>
            props.theme.id === 1
                ? "inherit !important"
                : `${props.theme.colors.linkText} !important`};
        text-decoration: none !important;
        display: flex;
        align-items: center;

        &.disabled {
            user-select: none;
            pointer-events: none;
            color: var(--gray5);
        }

        @media (max-width: 1368px) {
            font-size: 0.7rem;
        }
    }

    button {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.linkText};

        @media (max-width: 992px) {
            font-size: 0.7rem;
        }
    }

    .exit {
        color: ${(props) =>
            props.theme.id === 1
                ? "inherit !important"
                : `${props.theme.colors.linkText} !important`};
        text-decoration: none;

        @media (max-width: 1368px) {
            padding: 8px;
            font-size: 0.7rem;
        }
    }

    @media (max-width: 992px) {
        column-gap: 0;
    }

    @media (max-width: 576px) {
        flex-direction: column;
    }
`;

export const Brand = styled.div`
    display: flex;

    img {
        width: 86px;
        height: 86px;
    }
`;

export const ProfileControls = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 5px;
    padding-bottom: 10px;
    color: ${(props) => props.theme.colors.labelText};

    @media (max-width: 768px) {
        // em função dos elementos no header (menu de contexto e nome de perfil)
        display: none;
    }

    > span {
        max-width: 120px;
        word-wrap: break-word;
        white-space: break-spaces;

        // @media (max-width: 1070px) { // em função dos elementos no header (menu de contexto e nome de perfil)
        //     max-width: 60px;
        // }
    }
`;

// Search

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const SearchInputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 18px;
    margin-left: 10px;
    flex-grow: 1;
`;

export const SearchIcon = styled.div`
    position: absolute;
    top: 55%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--gray2);
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 8px 8px 8px 35px;
    font-size: 16px;
    border: 1px solid #828282;
    border-radius: 2px;
    flex-grow: 1;
`;
