import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Container = styled.div`
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
`;

export const Label = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 700;
`;

export const FileList = styled.ul`
    margin-top: 10px;
    list-style: none;
`;

export const FileItem = styled.li`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: var(--primary);
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    width: 100%;
`;

export const FileNumber = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

export const FileRemove = styled.div`
    cursor: pointer;
    margin-left: auto;
    color: var(--secondary);

    &:hover {
        color: var(--gray2);
    }
`;
