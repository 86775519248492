export interface Activity {
    id: number;
    name: string;
    type: string;
    deadline: string;
    progress: string;
    associations?: string[];
}

// Os tipos de atividades incluem: envio de arquivos, orientação,
// questionário, teste online, wiki, e encontros.

export const mockActivities: {
    open: Activity[];
    upcoming: Activity[];
    closed: Activity[];
} = {
    open: [
        {
            id: 1,
            name: "Wiki 2",
            type: "Wiki",
            deadline: "13.03.2024",
            progress: "04 de 75",
            associations: []
        },
        {
            id: 2,
            name: "Questionário 2",
            type: "Questionário",
            deadline: "07.11.2024",
            progress: "15 de 75",
            associations: []
        },
        {
            id: 3,
            name: "Encontro 2",
            type: "Encontro",
            deadline: "20.01.2024",
            progress: "10 de 50",
            associations: []
        }
    ],
    upcoming: [
        {
            id: 4,
            name: "Teste Online",
            type: "Teste Online",
            deadline: "10.10.2023",
            progress: "75 de 75",
            associations: []
        },
        {
            id: 5,
            name: "Orientação",
            type: "Orientação",
            deadline: "15.12.2023",
            progress: "20 de 100",
            associations: []
        },
        {
            id: 6,
            name: "Envio de Arquivos",
            type: "Envio de Arquivos",
            deadline: "01.05.2024",
            progress: "05 de 20",
            associations: []
        }
    ],
    closed: [
        {
            id: 7,
            name: "Wiki 1",
            type: "Wiki",
            deadline: "05.08.2023",
            progress: "75 de 75",
            associations: []
        },
        {
            id: 8,
            name: "Questionário 1",
            type: "Questionário",
            deadline: "22.07.2023",
            progress: "75 de 75",
            associations: []
        },
        {
            id: 9,
            name: "Encontro 1",
            type: "Encontro",
            deadline: "15.06.2023",
            progress: "75 de 75",
            associations: []
        }
    ]
};
