import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { activitiesData } from "./ActivitiesData";
import Title from "../../components/Title";
import Breadcrumb from "../../components/Breadcrumb";

interface ActivityPageProps {
    activityType: string;
    dataKey: keyof typeof activitiesData;
    user: string;
    announcement: string;
}

// Componente genérico da página de atividades
const ActivityPage: React.FC<ActivityPageProps> = ({
    activityType,
    dataKey,
    user,
    announcement
}) => {
    const { id } = useParams<{ id: string }>();
    const parsedId = id ? parseInt(id, 10) : NaN;

    const activityData =
        activitiesData[dataKey][
            parsedId as keyof (typeof activitiesData)[typeof dataKey]
        ];

    if (!activityData) {
        return <div>{activityType} não encontrado.</div>;
    }

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Banco de Recursos</span>
                <span>Atividades</span>
            </Breadcrumb>
            <Title>{activityData.title}</Title>
            <Info>
                <RightAlignedText>
                    <ColoredText>{user}</ColoredText> - {activityType}
                </RightAlignedText>
                <RightAlignedText>
                    <ColoredText>ATENÇÃO!</ColoredText> {announcement}
                </RightAlignedText>
            </Info>
            <Contents>{activityData.description}</Contents>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin: 10px 0;
    text-align: left;
`;

const Info = styled.div`
    text-align: right;
    margin: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.25em;
`;

const RightAlignedText = styled.div`
    text-align: right;
`;

const ColoredText = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;

const Contents = styled.p`
    line-height: 19px;
`;

export default ActivityPage;
