import React, { useEffect, useState, useRef, memo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Title from "../../components/Title";
import Breadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../shared/contexts/Auth";
import { useUser } from "../../shared/contexts/UserContext";
import { courseService } from "../../shared/services/courseService";
import {
    Container,
    Buttons,
    ModeButton,
    ImageTextContainer,
    ProfileImage,
    TextContainer,
    Table,
    Header,
    Row,
    DataCell,
    FooterCell,
    FinalRow,
    FinalCell,
    ParticipationTable,
    ParticipationHeader,
    ParticipationRow,
    ParticipationCell,
    HeaderRow
} from "./styles";
import { gradeData, participationData } from "./mockData";

// Componente de imagem memoizado
const MemoizedProfileImage = memo(({ src }: { src: string | null }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div style={{ position: "relative", width: "200px", height: "200px" }}>
            {/* O placeholder aparece somente enquanto a imagem
            estiver sendo carregada, geralmente no primeiro load. */}
            {!isLoaded && (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        backgroundColor: "var(--gray5)",
                        position: "absolute",
                        top: 0,
                        left: 0
                    }}
                />
            )}

            {/* Imagem de perfil do usuário */}
            {src && (
                <ProfileImage
                    src={src}
                    alt="Foto do Usuário"
                    onLoad={handleImageLoad}
                    style={{
                        display: isLoaded ? "block" : "none", // Oculta a imagem até que ela seja carregada
                        borderRadius: "50%"
                    }}
                />
            )}
        </div>
    );
});

// Componente de texto memoizado
const MemoizedTextContent = memo(
    ({ course, userName }: { course: string; userName: string }) => (
        <TextContainer>
            <div style={{ marginBottom: "40px" }}>
                <span style={{ fontWeight: "700", marginRight: "10px" }}>
                    Curso:
                </span>{" "}
                {course}
            </div>
            <div>
                <span style={{ fontWeight: "700", marginRight: "10px" }}>
                    Aluno(a):
                </span>{" "}
                {userName}
            </div>
        </TextContainer>
    )
);

const Transcript: React.FC = () => {
    const { getUserById } = useUser();
    const { logged, getStorageAuhtenticatedUser } = useAuth();
    const { userEditing } = useUser();

    const navigate = useNavigate();
    const profileImageSrcRef = useRef<string | null>(null);
    const [userName, setUserName] = useState<string | null>(
        localStorage.getItem("userName") || null
    );
    const [courseName, setCourseName] = useState<string>("");
    const { codigo } = useParams<{ codigo: string }>();
    const location = useLocation();
    const state = location.state as { courseId?: number };
    const courseId = state?.courseId || Number(codigo);

    const query = new URLSearchParams(location.search);
    const view =
        (query.get("view") as "transcript" | "participation") || "transcript";

    // O flag é mais um bandaid pra mitigar o problema dos requests.
    // Vale a pena investigar.
    const isUserFetchedRef = useRef(false);

    useEffect(() => {
        if (logged && !isUserFetchedRef.current) {
            const userAuthenticated = getStorageAuhtenticatedUser();
            if (!userAuthenticated?.id) return;

            getUserById(userAuthenticated.id);
            isUserFetchedRef.current = true; // Sinaliza que o usuário foi carregado

            if (userEditing?.nome) {
                setUserName(userEditing.nome);
                localStorage.setItem("userName", userEditing.nome);
            }

            if (courseId) {
                fetchCourseName(courseId);
            }
        }
    }, [
        logged,
        getStorageAuhtenticatedUser,
        getUserById,
        userEditing?.nome,
        courseId
    ]);

    const fetchCourseName = async (courseId: number) => {
        try {
            const response = await courseService.getCourseById(courseId);
            if (response && response.tituloLongo) {
                setCourseName(response.tituloLongo);
            }
        } catch (error) {
            console.error("Failed to fetch course name", error);
        }
    };

    useEffect(() => {
        const cachedImage = localStorage.getItem("profileImage");
        if (cachedImage) {
            profileImageSrcRef.current = cachedImage;
        } else if (userEditing?.fotoPerfilServer?.data) {
            const base64Image = `data:image/png;base64,${userEditing.fotoPerfilServer.data}`;
            profileImageSrcRef.current = base64Image;
            localStorage.setItem("profileImage", base64Image); // Salva imagem no localStorage
        }
    }, [userEditing?.fotoPerfilServer?.data]);

    const handleViewChange = (newView: "transcript" | "participation") => {
        navigate(`${location.pathname}?view=${newView}`);
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: {courseName || "Nome do Curso"}</span>
                <span>Histórico</span>
                <span>
                    {view === "transcript" ? "Boletim" : "Participação"}
                </span>
            </Breadcrumb>
            <Title>Histórico</Title>

            <ImageTextContainer>
                <MemoizedProfileImage src={profileImageSrcRef.current} />
                <MemoizedTextContent
                    course={courseName}
                    userName={userName || ""}
                />
            </ImageTextContainer>

            <Buttons>
                <ModeButton
                    isActive={view === "transcript"}
                    onClick={() => handleViewChange("transcript")}
                >
                    Boletim
                </ModeButton>
                <ModeButton
                    isActive={view === "participation"}
                    onClick={() => handleViewChange("participation")}
                >
                    Participação
                </ModeButton>
            </Buttons>

            {view === "transcript" ? (
                <div>
                    <Table>
                        {gradeData.sections.map((section, sectionIndex) => (
                            <React.Fragment key={sectionIndex}>
                                <thead>
                                    <Row>
                                        <Header>{section.header}</Header>
                                        <Header>Peso</Header>
                                        <Header>Nota</Header>
                                    </Row>
                                </thead>
                                <tbody>
                                    {section.rows.map((row, rowIndex) => (
                                        <Row key={rowIndex}>
                                            <DataCell>{row.atividade}</DataCell>
                                            <DataCell>
                                                {row.peso.toLocaleString(
                                                    "pt-BR",
                                                    { minimumFractionDigits: 1 }
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {row.nota.toLocaleString(
                                                    "pt-BR",
                                                    { minimumFractionDigits: 1 }
                                                )}
                                            </DataCell>
                                        </Row>
                                    ))}
                                </tbody>
                            </React.Fragment>
                        ))}
                        <tfoot>
                            <tr>
                                <FooterCell>
                                    {gradeData.footer.media}
                                </FooterCell>
                                <FooterCell></FooterCell>
                                <FooterCell>
                                    {gradeData.footer.mediaValue.toLocaleString(
                                        "pt-BR",
                                        { minimumFractionDigits: 1 }
                                    )}
                                </FooterCell>
                            </tr>
                            <FinalRow>
                                <FinalCell colSpan={2}>
                                    {gradeData.footer.mediaFinal}
                                </FinalCell>
                                <FinalCell>
                                    {gradeData.footer.mediaFinalValue.toLocaleString(
                                        "pt-BR",
                                        { minimumFractionDigits: 1 }
                                    )}
                                </FinalCell>
                            </FinalRow>
                        </tfoot>
                    </Table>
                </div>
            ) : (
                <div>
                    <ParticipationTable>
                        <tbody>
                            {participationData.sections.map(
                                (section, index) => (
                                    <React.Fragment key={index}>
                                        <HeaderRow>
                                            <ParticipationHeader>
                                                {section.header} [
                                                {section.items.length}]
                                            </ParticipationHeader>
                                        </HeaderRow>
                                        {section.items.length > 0 ? (
                                            section.items.map(
                                                (item, itemIndex) => (
                                                    <ParticipationRow
                                                        key={itemIndex}
                                                    >
                                                        <ParticipationCell>
                                                            16.02.2024 - 13:32h
                                                            :{" "}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "700"
                                                                }}
                                                            >
                                                                {item}
                                                            </span>
                                                        </ParticipationCell>
                                                    </ParticipationRow>
                                                )
                                            )
                                        ) : (
                                            <ParticipationRow>
                                                <ParticipationCell
                                                    style={{
                                                        fontWeight: "700"
                                                    }}
                                                >
                                                    Nenhuma participação
                                                </ParticipationCell>
                                            </ParticipationRow>
                                        )}
                                        <tr style={{ height: "20px" }}></tr>
                                    </React.Fragment>
                                )
                            )}
                        </tbody>
                    </ParticipationTable>
                </div>
            )}
        </Container>
    );
};

export default Transcript;
