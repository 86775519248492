import styled from "styled-components";

interface AlignProps {
    alignLeft?: boolean;
}

export const Container = styled.div`
    width: 100%;
    margin: 10px 0;
    text-align: left;
`;

// Conteúdo do popup

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #4f4f4f;
    padding: 20px 10px;
`;

export const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    padding: 4px;
    align-items: center;

    label {
        color: var(--labelText);
        text-align: left;
    }

    > div {
        min-width: 200px;
    }

    input[type="checkbox"] {
        transform: scale(1.7);
    }
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10.5px;
    text-align: right;
    font-weight: 700;
`;

export const Input = styled.input`
    width: 100%;
    padding: 8px;
    // margin-left: 7px;
    // margin-right: -0.3em;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;
    margin-bottom: 20px;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }

    &.custom-input {
        padding-left: 12px;
        margin-left: 5px;
        margin-right: -4px;
        margin-top: -4px;
        border: 2px solid var(--secondary);

        &::placeholder {
            color: inherit;
            opacity: 1;
        }
    }
`;

export const InputMasked = styled.input`
    width: 100%;
    padding: 8px;
    // margin-left: 7px;
    // margin-right: -0.3em;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }

    &.custom-input {
        padding-left: 12px;
        margin-left: 5px;
        margin-right: -4px;
        margin-top: -4px;
        border: 2px solid var(--secondary);

        &::placeholder {
            color: inherit;
            opacity: 1;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start-end;
    column-gap: 32px;
    margin-top: 1em;

    button {
        padding: 10px 20px;
        display: flex;
        align-items: center;

        svg {
            font-size: 20px;
            margin: 0 5px;
        }
    }
`;
