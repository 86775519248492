import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Title from "../../../components/Title";
import Breadcrumb from "../../../components/Breadcrumb";
import Editor from "../../../components/Editor";
import FileUpload from "../../../components/FileUpload";
import Button from "../../../components/Button";
import {
    Container,
    FormGroup,
    TitleCell,
    AuthorDateCell,
    Buttons
} from "./styles";
import styled from "styled-components";

const ReplyPage: React.FC = () => {
    const { postId } = useParams<{ postId: string }>();
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const navigate = useNavigate();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        console.log("Post: ", postId);
        console.log("Uploaded files: ", files);
    };

    const handleSubmit = () => {
        console.log("Submitting comment with files: ", uploadedFiles);
    };

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}/communication/forum/post/${postId}`);
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Comunicação</span>
                <span>Fórum</span>
            </Breadcrumb>

            <TableHeader>
                <TableRow>
                    <TitleCell>
                        At vero eos et accusam et justo duo dolores et ea rebum.
                        Stet clita kasd
                    </TitleCell>
                    <TableCell>Respostas</TableCell>
                    <TableCell>Visualizações</TableCell>
                    <TableCell>Curtidas</TableCell>
                </TableRow>
                <TableRow>
                    <AuthorDateCell>
                        Diego Xavier 26.01.2024{" "}
                        <span style={{ fontWeight: "400" }}>às 13:43:00</span>
                    </AuthorDateCell>
                    <TableCell>2</TableCell>
                    <TableCell>87</TableCell>
                    <TableCell>32</TableCell>
                    <TableCell />
                </TableRow>
            </TableHeader>

            <div style={{ display: "flex", justifyContent: "center" }}><Title>Resposta</Title></div>
            <ReplyToText>Respondendo a Diego Xavier:</ReplyToText>

            <QuotedComment>
                Fringilla est ullamcorper eget nulla facilisi etiam dignissim.
                Maecenas sed enim ut sem viverra aliquet eget. At imperdiet dui
                accumsan sit amet nulla facilisi morbi tempus? Fringilla est
                ullamcorper eget nulla facilisi etiam dignissim. Maecenas sed
                enim ut sem viverra aliquet eget. At imperdiet dui accumsan sit
                amet nulla facilisi morbi tempus?
            </QuotedComment>

            <p style={{ fontWeight: "700", marginBottom: "8px" }}>Mensagem:</p>
            <Editor />
            <FormGroup>
                <FileUpload onFileUpload={handleFileUpload} />
            </FormGroup>
            <Buttons>
                <Button type="submit" btnTheme="primary" onClick={handleSubmit}>
                    Enviar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

export const TableHeader = styled.div`
    display: table;
    width: 100%;
    background-color: var(--gray2);
    color: white;
    padding-top: 10px;
`;

const TableRow = styled.div`
    display: table-row;
`;

const TableCell = styled.div`
    display: table-cell;
    text-align: center;
    padding: 2px 10px;
    font-weight: bold;
`;

const ReplyToText = styled.div`
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
`;

const QuotedComment = styled.div`
    text-align: left;
    background-color: var(--primary-variation7);
    color: #000;
    padding: 4px;
    margin-bottom: 10px;
`;

export default ReplyPage;
