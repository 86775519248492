import { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/Auth";
import brand from "../../assets/img/logo_c.jpg";
import brandHighContrast from "../../assets/img/logo_c_branco.png";
import Button from '../../components/Button';
import { useAccessibility } from "../../shared/contexts/AccessibilityContext";
import { ProfileControls, Brand, Container, Links, SearchInputContainer, SearchIcon, SearchInput } from "./styles";
import { FaSearch } from "react-icons/fa";


const MainHeader: React.FC = () => {
    const { signOut, getStorageAuhtenticatedUser } = useAuth();
    const { hasCompleteRegistration } = useUser();
    const [search, setSearch] = useState('');
    const { theme } = useAccessibility();

    const getLogoImage = () => {
        return theme.id !== 2 ? brand : brandHighContrast;
    }

    const getPresentationName = () => {
        return getStorageAuhtenticatedUser()?.nome
            ? getStorageAuhtenticatedUser().nome.split(' ')[0]
            : "...";
    }

    return (
        <Container className="aux-main-header">
            <Brand>
                <Link to="/" title="Minha Constructore">
                    <img src={getLogoImage()} alt="Logo Constructore" />
                </Link>
            </Brand>
          <div>
            <SearchInputContainer>
              <SearchIcon>
                <FaSearch />
              </SearchIcon>
              <SearchInput
                type="search"
                placeholder="Buscar"
                id="mainSearch"
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              />
            </SearchInputContainer>
                <Links role="navigation">
                    <Link to='/' className={!hasCompleteRegistration ? 'disabled' : ''}>Minha Constructore</Link>
                    <Link to='/' className={!hasCompleteRegistration ? 'disabled' : ''}>Manual</Link>
                    <Link to='/' className={!hasCompleteRegistration ? 'disabled' : ''}>Imprimir</Link>
                    <Link to='/' className={!hasCompleteRegistration ? 'disabled' : ''}>Fale conosco</Link>
                    <Link to='/' className={!hasCompleteRegistration ? 'disabled' : ''}>Informações</Link>
                    <Button
                        className="exit"
                        btnTheme="link"
                        onClick={signOut}
                    >
                        Sair
                    </Button>
                </Links>
            </div>
            <ProfileControls>
                <span>Olá, {getPresentationName()}!</span>
            </ProfileControls>
        </Container>
    )
};

export default MainHeader;