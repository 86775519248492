import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ForgotPassword from '../pages/ForgotPassword';
import Register from '../pages/Register';
import SpinnerScreen from '../components/SpinnerScreen';

// import SignIn from '../pages/SignIn';

// const AuthRoutes: React.FC = () => (
//     <Routes>
//         <Route path="/" element={<SignIn/>} />
//         <Route path="/register" element={<Register/>} />
//         <Route path="/forgotpassword" element={<ForgotPassword/>} />
//     </Routes>
// );

const AuthRoutes: React.FC = () => {
    const SignIn = lazy(() => import('../pages/SignIn'));

    return (
        <Routes>
            <Route path="/" element={
                <Suspense fallback={<SpinnerScreen />}>
                    <SignIn/>
                </Suspense>
            }/>
            <Route path="/register" element={
                <Suspense fallback={<SpinnerScreen />}>
                    <Register/>
                </Suspense>
            }/>
            <Route path="/forgotpassword" element={
                <Suspense fallback={<SpinnerScreen />}>
                    <ForgotPassword/>
                </Suspense>
            }/>
        </Routes>
    )
};

export default AuthRoutes;