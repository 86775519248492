import styled from "styled-components";

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const GradientWrapper = styled.div`
background: #720e12;
background: ${(props) =>
            props.theme.id === 1
                ? "#720e12"
                : "#fff"};

    padding: 8px;
    border-radius: 2px;
`;

export const PopupContainer = styled.div`
    width: 845px;
    background-color: ${(props) => props.theme.colors.bgPrimary};
    border-radius: 2px;
    padding: 10px 60px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;

    @media (max-width: 992px) {
        width: 100%;
    }

    @media (max-width: 576px) {
        padding: 10px;
    }
`;
