import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin: 10px 0;
    text-align: left;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    margin: 16px 0;
`;

export const ModeButton = styled.button<{ isActive: boolean }>`
    border-radius: 100px;
    color: ${(props) =>
        props.isActive
            ? "#000" // No botão ativo, a cor do texto é sempre preta
            : props.theme.id === 1
              ? "#fff" // No inativo, branco no tema 1
              : "#000"}; // E preto no tema 2
    font-weight: 400;
    background: ${(props) =>
        props.isActive
            ? "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #5B0C0F"
            : props.theme.id === 1
              ? "var(--primary)" // tema 1 (inativo)
              : "#fff"}; // tema 2 (inativo)
    padding: 8px 16px;
    width: 148px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

export const ImageTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
`;

export const ProfileImage = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 10000px;
    object-fit: cover;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 40px 0;
    border-top: 2px solid #4f4f4f;
`;

export const Header = styled.th`
    text-align: left;
    padding: 8px;
    color: #000;
    border-width: 0px 1px 1px 0px;
    border-bottom: 1px solid;
    background-color: var(--gray4);
    background-color: ${(props) =>
        props.theme.id === 1 ? "var(--gray4)" : "#fff"};
    font-weight: bold;
`;

export const Row = styled.tr`
    border-bottom: 2px solid #4f4f4f;
`;

export const DataCell = styled.td`
    padding: 8px;
    border-width: 0px 0px 1px 0px;
    border-bottom: 1px solid;
`;

export const FooterCell = styled.td`
    padding: 8px;
    font-weight: bold;
    border-bottom: 2px solid #4f4f4f;
`;

export const FinalRow = styled.tr`
    color: #fff;
    background-color: ${(props) =>
        props.theme.id === 1
            ? props.theme.colors.primary
            : props.theme.colors.warning};
    font-weight: bold;
`;

export const FinalCell = styled.td`
    padding: 8px;
`;

// Participação

export const ParticipationTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 40px 0;
    border: 1px solid;
`;

export const ParticipationHeader = styled.th`
    color: #000;
    background-color: ${(props) =>
        props.theme.id === 1 ? "var(--gray4)" : "#fff"};

    font-weight: bold;
    border: 1px solid;
    padding: 8px;
    text-align: left;
`;

export const ParticipationRow = styled.tr`
    padding: 8px;
`;

export const ParticipationCell = styled.td`
    padding: 8px;
`;

export const HeaderRow = styled.tr`
    background-color: var(--gray4);
    font-weight: bold;
    border: 1px solid;
`;
