import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Title from "../../components/Title";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import ProgressBar from "../../components/ProgressBar";
import Popup from "../../components/Popup";
import InputSelectWrapper from "../../components/InputSelectWrapper";
import { useAccessibility } from "../../shared/contexts/AccessibilityContext";
import { mockResources } from "./mockResources";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import {
    Container,
    ButtonsContainer,
    ButtonGroup,
    ButtonWrapper,
    ProgressBarWrapper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableHeaderCell,
    CourseListContainer,
    CourseListItem,
    FormGroup,
    PopupTableContainer,
    PopupTitle
} from "./styles";

const Resources = () => {
    const navigate = useNavigate();
    const currentUsage = 47.6;
    const totalUsage = 100;
    const [selectedResourceId, setSelectedResourceId] = useState<number | null>(
        null
    );
    const [expandedRows, setExpandedRows] = useState<number[]>([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    const { theme } = useAccessibility();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;

    const togglePopup = () => {
        // TODO: As associações devem ser atualizadas sempre que o
        // usuário interage com o popup. Quando a janela abre, os
        // campos devem ser preenchidos com as associações salvas e de
        // acordo com o selectedResourceId. Pensar como simular isso
        // sem o backend.
        setIsPopupOpen(!isPopupOpen);
    };

    /* O link "[Ver|Ocultar] cursos" na terceira coluna expande e
       contrai os cursos associados alternadamente. */
    const toggleCourses = (index: number) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(index)
                ? prevExpandedRows.filter((i) => i !== index)
                : [...prevExpandedRows, index]
        );
    };

    const handleCheckboxChange = (id: number) => {
        setSelectedResourceId(selectedResourceId === id ? null : id);
    };

    const handleDeleteClick = () => {
        console.log(`Recurso ${selectedResourceId} excluído!`);
    };

    const handleDeleteConfirm = () => {
        openConfirmDialog({
            text: "Você tem certeza que deseja excluir este recurso?",
            title: "Excluir recurso",
            onConfirm: handleDeleteClick
        });
    };

    const handleButtonClick = (action: () => void) => {
        // Uma e somente uma opção deve ser selecionada
        if (selectedResourceId === null) {
            toast.error("Por favor, selecione um recurso.", {
                // Em tese, as cores do toast mudam conforme o tema.
                // Verificar se o mesmo acontece em outras páginas que
                // utilizam o toast.
                theme: theme.id !== 2 ? "light" : "dark"
            });
        } else {
            action();
        }
    };

    const handleEditClick = () => {
        if (selectedResourceId !== null) {
            const selectedResource = mockResources.find(
                (item) => item.id === selectedResourceId
            );
            navigate(
                `/courseenvironment/${courseId}/resources/edit/${selectedResourceId}`,
                {
                    state: {
                        resource: selectedResource
                    }
                }
            );
        } else {
            toast.error("Por favor, selecione um recurso.", {
                theme: theme.id !== 2 ? "light" : "dark"
            });
        }
    };

    const buttons = [
        {
            label: "Inserir",
            onClick: () =>
                navigate(`/courseenvironment/${courseId}/resources/new`)
        },
        {
            label: "Editar",
            onClick: handleEditClick
        },
        {
            label: "Excluir",
            onClick: () => handleButtonClick(handleDeleteConfirm)
        },
        {
            label: "Associar",
            onClick: () => handleButtonClick(togglePopup)
        },
        { label: "Download" }
    ];

    const courseModules = [
        { label: "A", value: "1" },
        { label: "B", value: "2" },
        { label: "C", value: "3" }
    ];

    // FIXME: fazer as modificações diretamente no SelectStyles
    const inputSelectStyle = {
        control: (provided: React.CSSProperties) => ({
            ...provided,
            borderRadius: "2px",
            border: "2px solid var(--secondary)"
        }),
        placeholder: (provided: React.CSSProperties) => ({
            ...provided,
            color: "#141414"
        })
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Banco de Recursos</span>
                <span>Recursos</span>
            </Breadcrumb>

            <Title>Recursos</Title>

            <ConfirmDialog />

            <ButtonsContainer>
                <ButtonGroup>
                    {buttons.map((button, index) => (
                        <ButtonWrapper key={index}>
                            <Button btnTheme="primary" onClick={button.onClick}>
                                {button.label}
                            </Button>
                        </ButtonWrapper>
                    ))}
                </ButtonGroup>
                <ProgressBarWrapper>
                    <ProgressBar
                        currentUsage={currentUsage}
                        totalUsage={totalUsage}
                    />
                </ProgressBarWrapper>
            </ButtonsContainer>

            <Popup isOpen={isPopupOpen} onClose={togglePopup}>
                <PopupTitle>Associar</PopupTitle>
                <FormGroup>
                    <PopupTableContainer>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o Curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o Módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a Atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a Comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                    </PopupTableContainer>
                </FormGroup>

                <Button
                    style={{
                        display: "block",
                        margin: "20px auto"
                    }}
                    btnTheme="primary"
                >
                    Salvar
                </Button>
            </Popup>

            <Table>
                <TableHead style={{ borderLeft: "none", borderRight: "none" }}>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell alignLeft>
                            Nome do Recurso
                        </TableHeaderCell>
                        <TableHeaderCell>Tipo</TableHeaderCell>
                        <TableHeaderCell>Curso</TableHeaderCell>
                        <TableHeaderCell>Inserido Por</TableHeaderCell>
                        <TableHeaderCell>Inserido Em</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <tbody>
                    {mockResources.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <input
                                    type="checkbox"
                                    checked={selectedResourceId === item.id}
                                    onChange={() =>
                                        handleCheckboxChange(item.id)
                                    }
                                />
                            </TableCell>
                            <TableCell alignLeft>
                                <a
                                    href={`/courseenvironment/${courseId}/resources/${item.id}`}
                                >
                                    {item.title}
                                </a>
                                <CourseListContainer
                                    expanded={expandedRows.includes(index)}
                                >
                                    {item.courses &&
                                        item.courses.length > 0 &&
                                        expandedRows.includes(index) && (
                                            <ul>
                                                {item.courses.map((curso, i) => (
                                                    <li>
                                                        <a
                                                            key={i}
                                                            href={`/courseenvironment/${typeof curso === "object" && curso !== null ? curso.code : "#"
                                                                }`}
                                                        >
                                                            {/* Verifica se curso é um objeto e renderiza o nome, caso contrário, renderiza o valor diretamente */}
                                                            {typeof curso === "string" ? curso : curso.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                </CourseListContainer>
                            </TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>
                                {item.courses && item.courses.length > 0 && (
                                    <Button
                                        style={{ fontSize: "16px" }}
                                        btnTheme="link"
                                        onClick={() => toggleCourses(index)}
                                    >
                                        {expandedRows.includes(index)
                                            ? "Ocultar cursos"
                                            : "Ver cursos"}
                                    </Button>
                                )}
                            </TableCell>
                            <TableCell>
                                <a
                                    href={`/courseenvironment/${courseId}/resources/#`}
                                >
                                    {item.AddedBy}
                                </a>
                            </TableCell>
                            <TableCell>{item.AddedOn}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Resources;
