import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../../../components/Title";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import HoverPopup from "../../../components/HoverPopup";
import { FaSearch, FaPencilAlt, FaTrash, FaDownload } from "react-icons/fa";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import {
    Container,
    SearchContainer,
    SearchInputContainer,
    SearchInput,
    SearchIcon,
    Icons,
    CommentDelete,
    Pagination,
    BibliographyItem,
    BibliographyTitle,
    BibliographyDetails,
    Column
} from "./styles";

/* Talvez valesse a pena incluir local e ano da publicação e um input
   opcional pro ISBN ou DOI. Inclusive já existe uma API pra isso. A
   ideia é que os dados sejam preenchido automaticamente. */

/* Seria bom também especificar o tipo de publicação: livro, artigo,
   capítulo de livro etc. */

const Bibliography = () => {
    const navigate = useNavigate();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    const bibliographyData = [
        {
            id: 1,
            bookName: "Making Value: Music, Capital, and the Social",
            author: "Timothy Taylor",
            editor: "Duke University Press",
            other: "Londres, 2024",
            link: "https://example.com/exemplo",
            attachments: ["attachments/exemplo.pdf", "attachments/exemplo.epub"]
        },
        {
            id: 2,
            bookName: "How Musical is Man?",
            author: "John Blacking",
            editor: "University of Washington Press",
            other: "Seatle, 1973",
            link: "https://example.com/exemplo",
            attachments: ["attachments/exemplo.pdf"]
        },
        {
            id: 3,
            bookName:
                "Why Suyá Sing: A Musical Anthropology of an Amazonian People",
            author: "Anthony Seeger",
            editor: "Cambridge University Press",
            other: "Cambridge, 1997",
            link: "https://example.com/exemplo",
            attachments: ["attachments/exemplo.pdf"]
        }
    ];

    const handleInsertClick = () => {
        navigate(`/courseenvironment/${courseId}/query/bibliography/new`);
    };

    const handleEditClick = (entry: (typeof bibliographyData)[0]) => {
        navigate(`/courseenvironment/${courseId}/query/bibliography/edit/${entry.id}`, { state: entry });
    };

    const handleDeleteClick = (entry: (typeof bibliographyData)[0]) => {
        openConfirmDialog({
            text: "Você tem certeza que deseja excluir esta publicação?",
            title: "Excluir publicação",
            onConfirm: () => {
                console.log("Deletando...", entry.bookName);
            }
        });
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Consulta</span>
                <span>Bibliografia</span>
            </Breadcrumb>
            <Title>Bibliografia</Title>
            <SearchContainer>
                <Button btnTheme="primary" onClick={handleInsertClick}>
                    Inserir
                </Button>
                <SearchInputContainer>
                    <SearchIcon>
                        <FaSearch />
                    </SearchIcon>
                    <SearchInput type="text" placeholder="Buscar publicação" />
                </SearchInputContainer>
            </SearchContainer>

            {bibliographyData.map((entry) => (
                <BibliographyItem key={entry.id}>
                    <BibliographyTitle>
                        <span>{entry.bookName}</span>
                        <Icons>
                            <FaDownload
                            /* TODO: onClick baixa os anexos */
                            />
                            <FaPencilAlt
                                onClick={() => handleEditClick(entry)}
                            />
                            <CommentDelete>
                                <FaTrash onClick={() => handleDeleteClick(entry)} />
                            </CommentDelete>
                        </Icons>
                    </BibliographyTitle>
                    <BibliographyDetails>
                        <Column>
                            <div>{entry.author}</div>
                            <div>{entry.editor}</div>
                            <div>{entry.other}</div>
                        </Column>
                        <Column>
                            <div>Anexos:</div>
                            {entry.attachments.map((file, idx) => (
                                <div key={idx}>
                                    <a
                                        href={file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {`Anexo ${idx + 1}`}
                                    </a>
                                </div>
                            ))}
                        </Column>
                        <Column>
                            <div><span>Associações</span></div>
                            <div>Atividade 1</div>
                            <div>Módulo 1</div>
                            <div>
                                <a
                                    href={entry.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Link 1
                                </a>
                            </div>
                        </Column>
                    </BibliographyDetails>
                </BibliographyItem>
            ))}
            <Pagination>
                <span>Página</span>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href="#"
                    style={{ color: "white", backgroundColor: "#949494" }}
                >
                    &lt;
                </a>
                <span>01</span>
                <span>de</span>
                <span>01</span>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href="#"
                    style={{ color: "white", backgroundColor: "#F34F4F" }}
                >
                    &gt;
                </a>
            </Pagination>
            <ConfirmDialog />
        </Container>
    );
};

export default Bibliography;
