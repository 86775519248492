// Formata datas usando o seguinte padrão: <b>DD.MM.YYYY</b> às HH:MMh

import React from "react";
import { DatePart, TimePart } from "./styles";

interface FormattedDateProps {
    dateString: string;
    bold?: boolean;
}

const FormattedDate: React.FC<FormattedDateProps> = ({ dateString, bold = false }) => {
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const datePart = `${day}.${month}.${year}`;
        const timePart = `às ${hours}:${minutes}h`;

        return (
            <span>
                <DatePart bold={bold}>{datePart}</DatePart> <TimePart>{timePart}</TimePart>
            </span>
        );
    };

    return <>{formatDate(dateString)}</>;
};

export default FormattedDate;
