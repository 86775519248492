import React from "react";
import ActivityPage from "../ActivityPage";

const Instruction = () => {
    return (
        <ActivityPage
            activityType="Orientação"
            dataKey="instructions"
            user="Patrícia Zudio"
            announcement="Você ainda tem que fazer o teste online!"
        />
    );
};

export default Instruction;