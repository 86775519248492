import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAsterisk,
    FaBox,
    FaCalendarAlt,
    FaChalkboardTeacher,
    FaEye,
    FaListUl,
    FaPencilRuler,
    FaPuzzlePiece,
    FaSatelliteDish,
    FaSdCard,
    FaServer,
    FaUserAlt,
    FaUserCog,
    // FaUserLock,
    FaUsers
} from "react-icons/fa";
import { useMenu } from "../../shared/contexts/MenuContext";
import { Container, Puller, Options } from "./styles";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/Auth";
import { useCourse } from "../../shared/contexts/CourseContext";
import { Module } from "../../shared/models/course/Module";

const Menu: React.FC = () => {
    const { menu, openOrCloseMenu, expanderManagementOption } = useMenu();
    const { hasCompleteRegistration } = useUser();
    const { hasPermission } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [trackedOption, setTrackedOption] = useState<number | null>(1);

    const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
    const [selectedSubmenuItem, setSelectedSubmenuItem] = useState<
        number | string | null
    >(null);

    const handleClick = useCallback(() => {
        if (menu.isOpen) {
            setOpenSubmenu(null); // Fecha todos os submenus abertos
        }
        openOrCloseMenu();
    }, [menu.isOpen, openOrCloseMenu]);

    const handleSubmenuClick = (submenu: string) => {
        if (!menu.isOpen) {
            openOrCloseMenu(); // Expande o menu se estiver retraído
            setOpenSubmenu(submenu); // E expande o submenu
        } else {
            if (openSubmenu === submenu) {
                // Se o submenu clicado já estiver aberto, recolha todo o menu
                openOrCloseMenu();
                setOpenSubmenu(null);
            } else {
                // Se outro submenu estiver aberto, feche-o e abra somente o submenu atual
                setOpenSubmenu(submenu);
            }
        }
    };

    const currentUrl = location.pathname;
    const courseCodeText = currentUrl.replace("/courseenvironment/", "");
    const courseCode = Number.parseInt(courseCodeText);

    const { fetchStepFour, dataStepFour } = useCourse();
    const [modules, setModules] = useState<Module[]>([]);
    const prevCourseCodeRef = useRef<number | null>(null);

    useEffect(() => {
        const fetchModules = async () => {
            if (
                currentUrl.includes("courseenvironment") &&
                courseCode !== prevCourseCodeRef.current
            ) {
                // console.log("Fetching modules for courseCode:", courseCode);
                prevCourseCodeRef.current = courseCode;
                await fetchStepFour(courseCode);
            }
        };

        fetchModules();
    }, [courseCode, fetchStepFour, currentUrl]);

    useEffect(() => {
        if (dataStepFour && dataStepFour.modulos) {
            setModules(dataStepFour.modulos);
            // console.log("Modules updated:", dataStepFour.modulos);
        }
    }, [dataStepFour]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const view = query.get("view");

        // Atualiza o item do submenu selecionado com base no parâmetro "view"
        if (view === "transcript" || view === "participation") {
            setSelectedSubmenuItem(view);
        }
    }, [location]);

    const handleMenuClick = (menuNumber: number) => {
        setTrackedOption(menuNumber);
        setSelectedSubmenuItem(null); // Reseta a seleção de item do submenu
    };

    const handleModuleClick = (module: Module) => {
        setSelectedSubmenuItem(module.id);
        navigate(`/courseenvironment/${courseCode}/module/${module.id}`, {
            state: {
                courseId: courseCode,
                moduleId: module.id,
                moduleName: module.nome,
                moduleDescription: module.descricao
            }
        });
    };

    const handleSubmenuItemClick = (item: number | string) => {
        setSelectedSubmenuItem(item);
    };

    const getSubmenuActiveClass = (id: number | string) =>
        selectedSubmenuItem === id ? "submenu-active" : "";

    const getMenuActiveClass = (id: number) =>
        trackedOption === id ? "active" : "";

    if (
        location.pathname?.includes("courseenvironment") ||
        location.pathname?.includes("module") ||
        location.pathname?.includes("participants")
    ) {
        return (
            <Container
                className={`aux-menu ${!hasCompleteRegistration ? "closed" : menu.isOpen ? "open" : "closed"}`}
                role="navigation"
            >
                <Options aria-label="Menu Ambiente de Curso">
                    <li>
                        <Link
                            id="first"
                            to={`/courseenvironment/${courseCode}`}
                            title="Apresentação"
                            onClick={() => {
                                handleMenuClick(1);
                                handleSubmenuClick("");
                            }}
                        >
                            <div>
                                <FaAsterisk className={getMenuActiveClass(1)} />
                                <span>Apresentação</span>
                            </div>
                        </Link>
                    </li>
                    {hasCompleteRegistration && (
                        <li className={trackedOption === 2 ? "active" : ""}>
                            <Link
                                to="#"
                                title="Módulos"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(2);
                                    handleSubmenuClick("modules");
                                }}
                            >
                                <div>
                                    <FaPuzzlePiece
                                        className={getMenuActiveClass(2)}
                                    />
                                    <span>Módulos</span>
                                </div>
                            </Link>
                            {openSubmenu === "modules" &&
                                modules.length > 0 && (
                                    <ul>
                                        {modules.map((module) => (
                                            <li key={module.id}>
                                                <a
                                                    href={`/courseenvironment/${courseCode}/module/${module.id}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleMenuClick(2);
                                                        handleModuleClick(
                                                            module
                                                        );
                                                    }}
                                                    className={getSubmenuActiveClass(
                                                        module.id
                                                    )}
                                                    title={module.nome}
                                                >
                                                    {module.nome}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li>
                            <Link
                                to="#"
                                title="Comunicação"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(3);
                                    handleSubmenuClick("communication");
                                }}
                            >
                                <div>
                                    <FaSatelliteDish
                                        className={getMenuActiveClass(3)}
                                    />
                                    <span>Comunicação</span>
                                </div>
                            </Link>
                            {openSubmenu === "communication" && (
                                <ul>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/communication/announcements`}
                                            title="Avisos"
                                            className={getSubmenuActiveClass(
                                                "announcements"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "announcements"
                                                )
                                            }
                                        >
                                            Avisos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/communication/forum`}
                                            title="Fórum"
                                            className={getSubmenuActiveClass(
                                                "forum"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick("forum")
                                            }
                                        >
                                            Fórum
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption === 4 ? "active" : ""}>
                            <Link
                                to="#"
                                title="Consulta"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(4);
                                    handleSubmenuClick("query");
                                }}
                            >
                                <div>
                                    <FaEye className={getMenuActiveClass(4)} />
                                    <span>Consulta</span>
                                </div>
                            </Link>
                            {openSubmenu === "query" && (
                                <ul>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/query/glossary`}
                                            title="Glossário"
                                            className={getSubmenuActiveClass(
                                                "glossary"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "glossary"
                                                )
                                            }
                                        >
                                            Glossário
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/query/bibliography`}
                                            title="Bibliografia"
                                            className={getSubmenuActiveClass(
                                                "bibliography"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "bibliography"
                                                )
                                            }
                                        >
                                            Bibliografia
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption === 5 ? "active" : ""}>
                            <Link
                                to="#"
                                title="Banco de Recursos"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(5);
                                    handleSubmenuClick("resources");
                                }}
                            >
                                <div>
                                    <FaListUl
                                        className={getMenuActiveClass(5)}
                                    />
                                    <span>Banco de Recursos</span>
                                </div>
                            </Link>
                            {openSubmenu === "resources" && (
                                <ul>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/resources`}
                                            title="Recursos"
                                            className={getSubmenuActiveClass(
                                                "resources"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "resources"
                                                )
                                            }
                                        >
                                            Recursos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/resources/activities`}
                                            title="Atividades"
                                            className={getSubmenuActiveClass(
                                                "activities"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "activities"
                                                )
                                            }
                                        >
                                            Atividades
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption === 6 ? "active" : ""}>
                            <Link
                                to="#"
                                title="Gestão do Curso"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(6);
                                    handleSubmenuClick("");
                                }}
                            >
                                <div>
                                    <FaBox className={getMenuActiveClass(6)} />
                                    <span>Gestão do Curso</span>
                                </div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption === 7 ? "active" : ""}>
                            <Link
                                to="#"
                                title="Participantes"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(7);
                                    handleSubmenuClick("participants");
                                }}
                            >
                                <div>
                                    <FaUsers
                                        className={getMenuActiveClass(7)}
                                    />
                                    <span>Participantes</span>
                                </div>
                            </Link>
                            {openSubmenu === "participants" && (
                                <ul>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/participants/students`}
                                            title="Alunos"
                                            className={getSubmenuActiveClass(
                                                "students"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "students"
                                                )
                                            }
                                        >
                                            Alunos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/participants/collaborators`}
                                            title="Colaboradores"
                                            className={getSubmenuActiveClass(
                                                "collaborators"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "collaborators"
                                                )
                                            }
                                        >
                                            Colaboradores
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/participants/teacher`}
                                            title="Professor"
                                            className={getSubmenuActiveClass(
                                                "teacher"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "teacher"
                                                )
                                            }
                                        >
                                            Professor
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption === 8 ? "active" : ""}>
                            <Link
                                to="#"
                                title="Histórico"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMenuClick(8);
                                    handleSubmenuClick("history");
                                }}
                            >
                                <div>
                                    <FaSdCard
                                        className={getMenuActiveClass(8)}
                                    />
                                    <span>Histórico</span>
                                </div>
                            </Link>
                            {openSubmenu === "history" && (
                                <ul>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/transcript?view=transcript`}
                                            title="Boletim"
                                            className={getSubmenuActiveClass(
                                                "transcript"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "transcript"
                                                )
                                            }
                                        >
                                            Boletim
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/courseenvironment/${courseCode}/transcript?view=participation`}
                                            title="Participação"
                                            className={getSubmenuActiveClass(
                                                "participation"
                                            )}
                                            onClick={() =>
                                                handleSubmenuItemClick(
                                                    "participation"
                                                )
                                            }
                                        >
                                            Participação
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    )}
                </Options>
                {hasCompleteRegistration && (
                    <Puller
                        onClick={handleClick}
                        aria-label={
                            menu.isOpen ? "Retrair menu" : "Expandir menu"
                        }
                    >
                        {menu.isOpen ? (
                            <FaAngleDoubleLeft />
                        ) : (
                            <FaAngleDoubleRight />
                        )}
                    </Puller>
                )}
            </Container>
        );
    }

    return (
        <Container
            className={`aux-menu ${!hasCompleteRegistration ? "closed" : menu.isOpen ? "open" : "closed"}`}
            role="navigation"
        >
            <Options aria-label="Menu principal">
                {hasCompleteRegistration && (
                    <li
                        className={
                            trackedOption === 1 || trackedOption === 0
                                ? "active"
                                : ""
                        }
                    >
                        <Link id="first" to="/mycourses" title="Meus cursos">
                            <div>
                                <FaChalkboardTeacher />
                                <span>Meus cursos</span>
                            </div>
                        </Link>
                    </li>
                )}
                <li className={trackedOption === 2 ? "active" : ""}>
                    <Link to="/mydata" title="Meus dados">
                        <div>
                            <FaUserAlt />
                            <span>Meus dados</span>
                        </div>
                    </Link>
                </li>
                {hasCompleteRegistration && (
                    <li className={trackedOption === 3 ? "active" : ""}>
                        <Link to="#" title="Agenda">
                            <div>
                                <FaCalendarAlt />
                                <span>Agenda</span>
                            </div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && hasPermission() && (
                    <li className={trackedOption === 4 ? "active" : ""}>
                        <Link
                            to="/course"
                            title="Criar curso"
                            reloadDocument={true}
                        >
                            <div>
                                <FaPencilRuler />
                                <span>Criar curso</span>
                            </div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && (
                    <li className={trackedOption === 5 ? "active" : ""}>
                        <Link to="#" title="Recursos">
                            <div>
                                <FaServer />
                                <span>Recursos</span>
                            </div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && (
                    <li>
                        <Link
                            to="#"
                            title="Administração"
                            onClick={expanderManagementOption}
                            className={`option-parent ${trackedOption === 6 ? "active" : ""}`}
                            aria-label={`Administração sub-menu: ${!menu.options.isOpenManagement || !menu.isOpen ? "fechado" : "aberto"}`}
                        >
                            <div>
                                <FaUserCog />
                                <span>Administração</span>
                            </div>
                        </Link>
                        {menu.options.isOpenManagement && (
                            <ul
                                className={`option-parent-list ${!menu.options.isOpenManagement || !menu.isOpen ? "closed" : "open"}`}
                            >
                                <li>Cursos</li>
                                <li>
                                    <Link
                                        to="/Admin"
                                        title="Usuários"
                                        aria-label="Usuários"
                                    >
                                        <span>Usuários</span>
                                    </Link>
                                </li>
                                <li>Layout</li>
                                <li>Estatística</li>
                                <li>Grupos de áreas de conhecimento</li>
                                <li>Perguntas de inscrição</li>
                                <li>Suporte técnico</li>
                            </ul>
                        )}
                    </li>
                )}
            </Options>
            {hasCompleteRegistration && (
                <Puller
                    onClick={handleClick}
                    aria-label={menu.isOpen ? "Retrair menu" : "Expandir menu"}
                >
                    {menu.isOpen ? (
                        <FaAngleDoubleLeft />
                    ) : (
                        <FaAngleDoubleRight />
                    )}
                </Puller>
            )}
        </Container>
    );
};

export default React.memo(Menu);
