import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Container } from "./styles";

interface EditorProps {
    content?: string;
    onChange?: (value: string) => void;
}

const Editor: React.FC<EditorProps> = ({ content = "", onChange }) => {
    const [value, setValue] = useState(content);

    useEffect(() => {
        setValue(content);
    }, [content]);

    const handleChange = (value: string) => {
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ indent: "-1" }, { indent: "+1" }, { direction: "rtl" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["link", "image", "video"],
            ["clean"]
        ]
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "background",
        "align",
        "script",
        "direction"
    ];

    return (
        <Container>
            <ReactQuill
                theme="snow"
                value={value}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
        </Container>
    );
};

export default Editor;
