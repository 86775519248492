import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import {
    Container,
    SubTitle,
    Input,
    FormGroup,
    TableContainer,
    LeftColumn,
    RightColumn,
    Label,
    Buttons,
    HorizontalFormGroup,
    HorizontalInput,
    HorizontalLabel,
    LinkButton
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import InputSelectWrapper from "../../../components/InputSelectWrapper";
import FileUpload from "../../../components/FileUpload";
// import { useAccessibility } from "../../../shared/contexts/AccessibilityContext";
import { DomainSelectOption } from "../../../shared/models/domains/Domains";

interface BibliographyEntryProps {
    mode: "new" | "edit";
}

interface EntryData {
    id: number;
    bookName: string;
    author: string;
    editor: string;
    other: string;
    links: string[];
}

const BibliographyEntry: React.FC<BibliographyEntryProps> = ({ mode }) => {
    const [bookName, setBookName] = useState("");
    const [author, setAuthor] = useState("");
    const [editor, setEditor] = useState("");
    const [other, setOther] = useState("");
    const [links, setLinks] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;

    const courseModules: DomainSelectOption[] = [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        { value: "3", label: "C" }
    ];

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        console.log("Uploaded files: ", files);
    };

    useEffect(() => {
        if (mode === "edit" && location.state) {
            const entry = location.state as EntryData; // Type assertion
            setBookName(entry.bookName);
            setAuthor(entry.author);
            setEditor(entry.editor);
            setOther(entry.other);
            setLinks(entry.links || []);
        }
    }, [mode, location.state]);

    const handleBookNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setBookName(event.target.value);
    };

    const handleAuthorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuthor(event.target.value);
    };

    const handleEditorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditor(event.target.value);
    };

    const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOther(event.target.value);
    };

    const handleLinksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLinks(event.target.value.split(","));
    };

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}/query/bibliography`);
    };

    const handleSave = () => {
        const payload = {
            bookName,
            author,
            editor,
            other,
            links
        };

        if (mode === "new") {
            fetch("/api/bibliography", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(() => {
                navigate(`/courseenvironment/${courseId}/query/bibliography`);
            });
        } else {
            fetch(`/api/bibliography/${(location.state as EntryData).id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(() => {
                navigate(`/courseenvironment/${courseId}/query/bibliography`);
            });
        }
    };

    const inputSelectStyle = {
        control: (provided: React.CSSProperties) => ({
            ...provided,
            borderRadius: "2px",
            border: "2px solid var(--secondary)"
        }),
        placeholder: (provided: React.CSSProperties) => ({
            ...provided,
            color: "#141414"
        })
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Consulta</span>
                <span>Bibliografia</span>
                <span>
                    {mode === "new"
                        ? "Inserir Referência"
                        : "Editar Referência"}
                </span>
            </Breadcrumb>

            <Title>Bibliografia</Title>
            <SubTitle>
                {mode === "new" ? "Inserir Referência" : "Editar Referência"}
            </SubTitle>

            <HorizontalFormGroup>
                <HorizontalLabel htmlFor="entry-bookName">
                    Título:
                </HorizontalLabel>
                <HorizontalInput
                    id="entry-bookName"
                    type="text"
                    value={bookName}
                    onChange={handleBookNameChange}
                    placeholder="Digite o título da publicação"
                />
            </HorizontalFormGroup>

            <HorizontalFormGroup>
                <HorizontalLabel htmlFor="entry-author">
                    Autor(a):
                </HorizontalLabel>
                <HorizontalInput
                    id="entry-author"
                    type="text"
                    value={author}
                    onChange={handleAuthorChange}
                    placeholder="Digite o autor"
                />
            </HorizontalFormGroup>

            <HorizontalFormGroup>
                <HorizontalLabel htmlFor="entry-editor">
                    Editora:
                </HorizontalLabel>
                <HorizontalInput
                    id="entry-editor"
                    type="text"
                    value={editor}
                    onChange={handleEditorChange}
                    placeholder="Digite a editora"
                />
            </HorizontalFormGroup>

            <HorizontalFormGroup>
                <HorizontalLabel htmlFor="entry-other">Outros:</HorizontalLabel>
                <HorizontalInput
                    id="entry-other"
                    type="text"
                    value={other}
                    onChange={handleOtherChange}
                    placeholder="Digite outras informações"
                />
            </HorizontalFormGroup>

            <FormGroup>
                <TableContainer>
                    <LeftColumn>
                        <p>Associação:</p>
                    </LeftColumn>
                    <RightColumn>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o Curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o Módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a Atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a Comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <FormGroup>
                            <Label htmlFor="notice">Aviso:</Label>
                            <Input
                                id="notice"
                                type="text"
                                placeholder="Aviso..."
                            />
                        </FormGroup>
                    </RightColumn>
                </TableContainer>
            </FormGroup>

            <FormGroup>
                <HorizontalLabel htmlFor="entry-link">Link:</HorizontalLabel>
                <Input
                    id="entry-link"
                    type="text"
                    value={links.join(",")}
                    onChange={handleLinksChange}
                    placeholder="Digite o link"
                />
                <LinkButton>
                    <FaPlus className="icon" />
                    <span className="text">Link</span>
                </LinkButton>
            </FormGroup>

            <FormGroup>
                <FileUpload onFileUpload={handleFileUpload} />
            </FormGroup>

            <Buttons>
                <Button type="submit" btnTheme="primary" onClick={handleSave}>
                    Salvar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

export default BibliographyEntry;
