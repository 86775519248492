import styled from "styled-components";

interface AlignProps {
    alignLeft?: boolean;
}

export const Container = styled.div`
    width: 100%;
    margin: 10px 0;
    text-align: left;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 768px) {
        gap: 10px;
        justify-content: space-between;
    }
`;

export const ButtonWrapper = styled.div`
    width: 122px;
    height: 40px;

    button {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 768px) {
        width: calc(
            50% - 5px
        ); /* Dois botões por fileira com espaço entre eles */

        &:nth-child(5) {
            width: 100%; /* O último botão full width para fechar o retângulo */
        }
    }
`;

export const AttachmentsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 20px;

    @media (max-width: 768px) {
        /* Reverte a ordem do stack: barra de progresso primeiro, label anexos segundo */
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

export const ProgressBarWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    margin-top: 20px;

    @media (max-width: 768px) {
        margin-left: 0;
        justify-content: flex-start;
        width: 100%;
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
`;

const backgroundGradient = `
    linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
    )`;

const getBackgroundColor = (themeId: number) => {
    return themeId === 1 ? "#5b0c0f" : "#fff";
};

export const TableHead = styled.thead`
    color: #000;
    background: ${(props) =>
        `${backgroundGradient}, ${getBackgroundColor(props.theme.id)}`};
    border: 2px solid #4f4f4f;
`;

export const TableRow = styled.tr`
    border-bottom: 2px solid #4f4f4f;
`;

export const TableHeaderCell = styled.th<AlignProps>`
    padding: 10px;
    font-weight: bold;
    background-color: inherit;
    text-align: ${({ alignLeft }) => (alignLeft ? "left" : "center")};
`;

export const TableCell = styled.td<AlignProps>`
    padding: 10px;
    text-align: ${({ alignLeft }) => (alignLeft ? "left" : "center")};

    input[type="checkbox"] {
        transform: scale(1.7);
    }

    /* Permite que os itens da lista quebrem dentro da célula, evitando que as outras células se desloquem. Isso acontece quando o nome do recurso é menor do que algum dos títulos dos cursos associados. */

    &:nth-child(2) {
        max-width: 200px; /* Define largura maior para coluna "Nome" */
        width: 30%;
        word-wrap: break-word;
    }

    ul {
        margin: 0;
        list-style-type: none;
        word-wrap: break-word;
    }
`;

export const CourseListContainer = styled.div<{ expanded: boolean }>`
    overflow: hidden;
    transition: max-height 0.3s ease;

    ul {
        li {
            margin-left: 1em;
            line-height: 19px;
            margin-top: 10px;
            color: var(--primary);

            &:first-child {
                margin-top: 10px;
            }
        }
    }
`;

export const CourseListItem = styled.li`
    margin-left: 1em;
    line-height: 19px;
    margin-top: 10px;
    color: var(--primary);

    &:first-child {
        margin-top: 10px;
    }
`;

// Conteúdo do popup

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 4px;

    label {
        color: var(--labelText);
        text-align: left;
    }

    input[type="checkbox"] {
        transform: scale(1.7);
    }
`;

export const PopupTableContainer = styled.div`
    flex: 1;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
        font-size: 14px;
    }
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10.5px;
    text-align: right;
    font-weight: 700;
`;

export const Input = styled.input`
    width: 100%;
    padding: 8px;
    // margin-left: 7px;
    // margin-right: -0.3em;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }

    &.custom-input {
        padding-left: 12px;
        margin-left: 5px;
        margin-right: -4px;
        margin-top: -4px;
        border: 2px solid var(--secondary);

        &::placeholder {
            color: inherit;
            opacity: 1;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start-end;
    column-gap: 32px;
    margin-top: 1em;
`;

export const PopupTitle = styled.div`
    font-weight: 700;
    line-height: 19px;
    text-align: right;
    color: ${(props) =>
        props.theme.id === 1
            ? "var(--primary)"
            : `${props.theme.colors.linkText} !important`};
`;

export const SubTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-bottom: 10px;
    color: ${({ theme }) =>
        theme.id === 1 ? theme.colors.titleText : theme.colors.primary};
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`;

export const DetailRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
`;

export const DetailLabel = styled.div`
    min-width: 120px;
    font-weight: bold;
`;

export const DetailValue = styled.div`
    flex: 1;
    word-wrap: break-word;
    margin-left: 30px;
    line-height: 19px;
`;

export const AnnouncementContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const Announcement = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    line-height: 19px;

    max-width: 1007px;
    height: auto;

    span:first-child {
        color: ${(props) => props.theme.colors.linkText};
    }

    @media (max-width: 768px) {
        justify-content: flex-end;
        flex-wrap: wrap;
    }
`;

// Associações

export const TableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const LeftColumn = styled.div`
    flex: 1;
    margin-top: 8em;

    @media (max-width: 768px) {
        margin-top: 2em;
    }
`;

export const RightColumn = styled.div`
    flex: 1;
`;

// Tabela pequena

export const SmallTableContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    z-index: 0;
`;

export const SmallTable = styled.table`
    border-collapse: collapse;
    width: 193px;
    padding: 8px;
    background-color: ${(props) => props.theme.colors.bgPrimary};
    color: ${(props) => props.theme.colors.labelText};
    position: relative;
    z-index: 1;
`;

export const SmallTableRow = styled.tr`
    border: 1px solid;
    border-radius: 2px 2px 0px 0px;

    &.tabheader {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            ${(props) => (props.theme.id === 1 ? "#fff" : "var(--gray2)")};
        color: ${(props) => props.theme.colors.labelText};
        font-weight: 700;
    }
`;

export const SmallTableCell = styled.td`
    padding: 8px;
    font-size: 14px;
    text-align: right;
`;

export const SmallTableBorderWrapper = styled.div`
    background: ${(props) => (props.theme.id === 1 ? "#720e12" : "#fff")};
    border-radius: 2px;
    padding: 8px; /* Espessura da borda */
    display: inline-block;
`;
