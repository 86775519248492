import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
    Container,
    ButtonsContainer,
    ButtonGroup,
    ButtonWrapper,
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableCell,
    SmallTableContainer,
    SmallTable,
    SmallTableRow,
    SmallTableCell,
    SmallTableBorderWrapper,
    PopupTitle,
    FormGroup,
    PopupTableContainer
} from "./styles";
import { FaPencilAlt } from "react-icons/fa";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import Button from "../../components/Button";
import HoverPopup from "../../components/HoverPopup";
import Popup from "../../components/Popup";
import { mockActivities, Activity } from "./mockActivities";
import InputSelectWrapper from "../../components/InputSelectWrapper";
import { toast } from "react-toastify";
import { useAccessibility } from "../../shared/contexts/AccessibilityContext";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";

// Função para determinar a rota para cada tipo de atividade
const getActivityRoute = (courseId: string, type: string, id: number) => {
    const typeMapping: { [key: string]: string } = {
        Questionário: "questionnaire",
        Orientação: "instruction",
        Wiki: "wiki",
        Encontro: "meeting",
        "Teste Online": "quiz",
        "Envio de Arquivos": "documents"
    };

    // Retorna um fallback caso o tipo não exista no mapping
    const routeType = typeMapping[type] || "";
    // FIXME:
    return routeType
        ? `/courseenvironment/${courseId}/resources/activities/${routeType}/${id}`
        : "#";
};

const courseModules = [
    { label: "A", value: "1" },
    { label: "B", value: "2" },
    { label: "C", value: "3" }
];

const inputSelectStyle = {
    control: (provided: React.CSSProperties) => ({
        ...provided,
        borderRadius: "2px",
        border: "2px solid var(--secondary)"
    }),
    placeholder: (provided: React.CSSProperties) => ({
        ...provided,
        color: "#141414"
    })
};

const Activities = () => {
    // Estado para gerenciar a atividade e tabela selecionada
    const [selectedActivity, setSelectedActivity] = useState<{
        tableKey: string;
        id: number | null;
    }>({ tableKey: "", id: null });

    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { theme } = useAccessibility();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo || "";
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    const handleCheckboxChange = (tableKey: string, id: number) => {
        setSelectedActivity((prevState) => ({
            tableKey,
            id: prevState.id === id ? null : id // Toggle seleção
        }));
    };

    const handleDeleteClick = () => {
        console.log("Atividade excluída!");
    };

     const handleDeleteConfirm = () => {
        openConfirmDialog({
            text: "Você tem certeza que deseja excluir esta atividade?",
            title: "Excluir atividade",
            onConfirm: handleDeleteClick,
        });
    };

    // const handleEditClick = () => {
    //     console.log("Editando...");
    // };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleButtonClick = (action: () => void) => {
        // Verifica se uma atividade foi selecionada
        if (selectedActivity.id === null) {
            toast.error("Por favor, selecione uma atividade.", {
                theme: theme.id !== 2 ? "light" : "dark"
            });
        } else {
            action();
        }
    };

    const buttons = [
        {
            label: "Inserir",
            onClick: () =>
                navigate(
                    `/courseenvironment/${courseId}/resources/activities/new`
                )
        },
        {
            label: "Associar",
            onClick: () => handleButtonClick(togglePopup)
        },
        {
            label: "Excluir",
            onClick: () => handleButtonClick(handleDeleteConfirm)
        }
    ];

    // Função para renderizar as tabelas
    const renderTable = (
        title: string,
        activities: Activity[],
        tableKey: string
    ) => (
        <Table>
            <TableHead>
                <TableRow style={{ borderBottom: "none" }}>
                    {/* Coluna das checkboxes */}
                    <TableHeaderCell />
                    <TableHeaderCell
                        colSpan={5}
                        style={{
                            paddingBottom: "0",
                            fontWeight: "bold",
                            textAlign: "left"
                        }}
                    >
                        <div style={{ marginBottom: "10px" }}>{title}</div>
                    </TableHeaderCell>
                </TableRow>
                <TableRow style={{ borderTop: "none" }}>
                    <TableHeaderCell />
                    <TableHeaderCell alignLeft style={{ paddingTop: "0" }}>
                        Nome:
                    </TableHeaderCell>
                    <TableHeaderCell>Tipo</TableHeaderCell>
                    <TableHeaderCell>Prazo</TableHeaderCell>
                    <TableHeaderCell>Corrigido</TableHeaderCell>
                    <TableHeaderCell />
                </TableRow>
            </TableHead>
            <tbody>
                {activities.map((item) => (
                    <TableRow key={item.id}>
                        <TableCell>
                            <input
                                type="checkbox"
                                checked={
                                    selectedActivity.tableKey === tableKey &&
                                    selectedActivity.id === item.id
                                }
                                onChange={() =>
                                    handleCheckboxChange(tableKey, item.id)
                                }
                            />
                        </TableCell>
                        <TableCell alignLeft>
                            <FaPencilAlt
                                style={{
                                    cursor: "pointer",
                                    marginRight: "8px"
                                }}
                            />
                            <Link
                                to={getActivityRoute(
                                    courseId,
                                    item.type,
                                    item.id
                                )}
                            >
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{item.deadline}</TableCell>
                        <TableCell>{item.progress}</TableCell>
                        <TableCell>
                            <HoverPopup linkText="Associações" />
                        </TableCell>
                    </TableRow>
                ))}
            </tbody>
        </Table>
    );

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Banco de Recursos</span>
                <span>Atividades</span>
            </Breadcrumb>

            <Title>Atividades</Title>

            <ButtonsContainer>
                <ButtonGroup style={{ flexWrap: "nowrap" }}>
                    {buttons.map((button, index) => (
                        <ButtonWrapper key={index}>
                            <Button btnTheme="primary" onClick={button.onClick}>
                                {button.label}
                            </Button>
                        </ButtonWrapper>
                    ))}
                </ButtonGroup>
            </ButtonsContainer>

            {renderTable("Atividades Abertas", mockActivities.open, "open")}
            {renderTable(
                "Próximas Atividades",
                mockActivities.upcoming,
                "upcoming"
            )}
            {renderTable(
                "Atividades Encerradas",
                mockActivities.closed,
                "closed"
            )}

            {/* Tabela pequena */}  
            <SmallTableContainer>
                <SmallTableBorderWrapper>
                    <SmallTable>
                        <thead>
                            <SmallTableRow className="tabheader">
                                <SmallTableCell style={{ textAlign: "center" }}>
                                    Atividades Abertas
                                </SmallTableCell>
                            </SmallTableRow>
                        </thead>
                        <tbody>
                            <SmallTableRow>
                                <SmallTableCell>2 Atividades</SmallTableCell>
                            </SmallTableRow>
                            <SmallTableRow>
                                <SmallTableCell>4 Avisos</SmallTableCell>
                            </SmallTableRow>
                            <SmallTableRow>
                                <SmallTableCell>3 Encontros</SmallTableCell>
                            </SmallTableRow>
                            <SmallTableRow>
                                <SmallTableCell>
                                    9 Itens de bibliografia
                                </SmallTableCell>
                            </SmallTableRow>
                            <SmallTableRow>
                                <SmallTableCell>5 Recursos</SmallTableCell>
                            </SmallTableRow>
                        </tbody>
                    </SmallTable>
                </SmallTableBorderWrapper>
            </SmallTableContainer>

            {/* Popup */}
            <Popup isOpen={isPopupOpen} onClose={togglePopup}>
                <PopupTitle>Associar</PopupTitle>
                <FormGroup>
                    <PopupTableContainer>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o Curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o Módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a Atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a Comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                    </PopupTableContainer>
                </FormGroup>
                <Button
                    style={{
                        display: "block",
                        margin: "20px auto"
                    }}
                    btnTheme="primary"
                >
                    Salvar
                </Button>
            </Popup>
            <ConfirmDialog />
        </Container>
    );
};

export default Activities;
