import React from "react";
import {
    Overlay,
    GradientWrapper,
    PopupContainer
} from "./styles";

interface PopupProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    // Impede que o clique dentro do container feche o popup.
    const handleContainerClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <Overlay onClick={onClose}>
            <GradientWrapper>
                <PopupContainer onClick={handleContainerClick}>
                    {children}
                </PopupContainer>
            </GradientWrapper>
        </Overlay>
    );
};

export default Popup;
