import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Container,
    Input,
    FormGroup,
    TableContainer,
    LeftColumn,
    RightColumn,
    Label,
    Buttons
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import InputSelectWrapper from "../../../components/InputSelectWrapper";
import FileUpload from "../../../components/FileUpload";
import Editor from "../../../components/Editor";
import { DomainSelectOption } from "../../../shared/models/domains/Domains";

const inputSelectStyle = {
    control: (provided: React.CSSProperties) => ({
        ...provided,
        borderRadius: "2px",
        border: "2px solid var(--secondary)"
    }),
    placeholder: (provided: React.CSSProperties) => ({
        ...provided,
        color: "#141414"
    })
};

const NewPost: React.FC = () => {
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const navigate = useNavigate();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
    };

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        console.log("Submitting files: ", uploadedFiles);
        console.log("Uploaded files: ", files);
    };

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}/communication/forum`);
    };

    const courseModules: DomainSelectOption[] = [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        { value: "3", label: "C" }
    ];

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Comunicação</span>
                <span>Fórum</span>
                <span>Nova Postagem</span>
            </Breadcrumb>
            <Title>Nova Postagem</Title>

            <FormGroup>
                <Label htmlFor="post-title" style={{ fontWeight: "700" }}>Título do Tópico:</Label>
                <Input
                    id="post-title"
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Digite o título do tópico"
                />
            </FormGroup>

            <FormGroup>
                <TableContainer>
                    <LeftColumn>
                        <p style={{ fontWeight: "700", marginBottom: "125px" }}>Conteúdo do Tópico:</p>
                        <p style={{ fontWeight: "700" }}>Associação:</p>
                    </LeftColumn>
                    <RightColumn>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o Curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o Módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a Atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a Comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <FormGroup>
                            <Label htmlFor="notice">Aviso:</Label>
                            <Input
                                id="notice"
                                type="text"
                                placeholder="Este texto serve de base para o teste..."
                            />
                        </FormGroup>
                    </RightColumn>
                </TableContainer>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="post-link" style={{ fontWeight: "700" }}>Link:</Label>
                <Input
                    id="post-link"
                    type="text"
                    value={link}
                    onChange={handleLinkChange}
                    placeholder="https://link.teste.ex"
                />
            </FormGroup>

            <FormGroup>
                <Editor />
            </FormGroup>

            <FormGroup>
                <FileUpload onFileUpload={handleFileUpload} />
            </FormGroup>

            <Buttons>
                <Button type="submit" btnTheme="primary">
                    Salvar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

export default NewPost;
