import React, { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ThemeProvider, useTheme } from "styled-components";
import Button from "../Button";
import { Buttons, Dialog } from "./styles";

interface Props {
    text: string;
    title?: string;
    onClickConfirm?: () => void;
    bgColor?: string;
    onCloseDialog?: () => void;
}

const ConfirmAlert: React.FC<Props> = ({
    text,
    title = "",
    onClickConfirm,
    bgColor = "#fff",
    onCloseDialog
}) => {
    const theme = useTheme(); // Usa o tema atual dentro do componente

    useEffect(() => {
        const showConfirmDialog = () => {
            confirmAlert({
                overlayClassName: "overlay",
                customUI: ({ onClose }) => (
                    <ThemeProvider theme={theme}>
                        <Dialog bgColor={bgColor}>
                            <h1>{title}</h1>
                            <p>{text}</p>
                            <Buttons>
                                <Button
                                    btnTheme="primary"
                                    onClick={() => {
                                        onClickConfirm && onClickConfirm();
                                        onClose();
                                        onCloseDialog && onCloseDialog();
                                    }}
                                >
                                    Sim
                                </Button>
                                <Button
                                    btnTheme="primary"
                                    onClick={() => {
                                        onClose();
                                        onCloseDialog && onCloseDialog();
                                    }}
                                    autoFocus
                                >
                                    Não
                                </Button>
                            </Buttons>
                        </Dialog>
                    </ThemeProvider>
                )
            });
        };
        showConfirmDialog();
    }, [bgColor, onClickConfirm, onCloseDialog, text, theme, title]);

    return null;
};

export default ConfirmAlert;
