import React, { useState } from "react";
import { Container, PopupWrapper, PopupContainer, LinkButton } from "./styles";

interface HoverPopupProps {
    linkText: string;
    position?: "left" | "center" | "right";
    bold?: boolean;
}

const HoverPopup: React.FC<HoverPopupProps> = ({
    linkText,
    position = "center",
    bold = true
}) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <Container position={position}>
            <LinkButton
                btnTheme="link"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                bold={bold}
            >
                {linkText}
            </LinkButton>
            {hovered && (
                <PopupWrapper
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <PopupContainer>
                        <div className="inner">
                            <p><strong>Associações</strong></p>
                            <p>Módulo</p>
                            <p className="indented">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#">Módulo 1</a>
                            </p>
                            <p>Atividade</p>
                            <p className="indented">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#">Atividade 1</a>
                            </p>
                            <p>Links</p>
                            <p className="indented">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#">Link 1</a>
                            </p>
                        </div>
                    </PopupContainer>
                </PopupWrapper>
            )}
        </Container>
    );
};

export default HoverPopup;
