import React from 'react';
import { Container } from './styles';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
};

const Breadcrumb: React.FC<Props> = ({ children }) => (
    <Container>
        { children }
    </Container>
);

export default Breadcrumb;