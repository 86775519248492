import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Container,
    Buttons,
    Content,
    TableRow,
    TableCell,
    Pagination,
    SearchContainer,
    SearchInputContainer,
    SearchIcon,
    SearchInput,
    SearchContent,
    Infos,
    ImageTextContainer,
    ProfileImage,
    TextContainer
} from "./styles";
import { useUser } from "../../../shared/contexts/UserContext";
import { AuthUser } from "../../../shared/models/Auth";
import { FaSearch } from "react-icons/fa";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import dialogConfirm from "../../../components/ConfirmAlert";
import FormattedDateBr from "../../../components/FormattedDateBr";
import { useAccessibility } from "../../../shared/contexts/AccessibilityContext";

interface LocationState {
    courseId: number;
    participantId: number;
    participantName: string;
    participantBirthday: string;
    participantPoints: string;
    participantCpf: string;
    participantDre: string;
    participantRole: string;
}

const MemoizedProfileImage = memo(({ src }: { src: string | null }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div style={{ position: "relative", width: "200px", height: "200px" }}>
            {!isLoaded && (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        backgroundColor: "var(--gray5)",
                        position: "absolute",
                        top: 0,
                        left: 0
                    }}
                />
            )}

            {src && (
                <ProfileImage
                    src={src}
                    alt="Foto do Usuário"
                    onLoad={handleImageLoad}
                    style={{
                        display: isLoaded ? "block" : "none",
                        borderRadius: "50%"
                    }}
                />
            )}
        </div>
    );
});

const ParticipantsRead: React.FC = () => {
    const location = useLocation();
    const state = location.state as LocationState;
    const { participantId, participantName, participantBirthday, participantPoints, participantCpf, participantDre, participantRole } = state || {};
    const { theme } = useAccessibility();

    const { userEditing } = useUser();
    const currentUrl = location.pathname;
    const participantsAttribute = currentUrl.replace(/^.*participants\//, "");
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;

    const getProfile = () => {
        if (participantsAttribute == 'students') {
            return "ALUNO";
        }

        if (participantsAttribute == 'collaborators') {
            return "COLABORADOR";
        }

        if (participantsAttribute == 'teacher') {
            return "PROFESSOR";
        }
    }

    const { users, fetchUsers, isLoading } = useUser();

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const getTitle = () => {
        if (participantsAttribute == 'students') {
            return "Alunos";
        }

        if (participantsAttribute == 'collaborators') {
            return "Colaboradores";
        }

        if (participantsAttribute == 'teacher') {
            return "Professor";
        }

        return 'N/A';
    };

    const getUserProfiles = () => {
        return users?.filter(user =>
            user.perfis.some((perfil: { name: string; }) => perfil.name === getProfile())
        );
    };

    const profiles = getUserProfiles() || [];

    const navigate = useNavigate();

    const handleTeacher = () => {
        navigate(`/courseenvironment/${courseId}/participants/teacher`);
    };

    const handleStudents = () => {
        navigate(`/courseenvironment/${courseId}/participants/students`);
    };

    const handleCollaborators = () => {
        navigate(`/courseenvironment/${courseId}/participants/collaborators`);
    };

    const [user] = useState<AuthUser | null>(null);
    const isStudent = user?.perfis.some(permission => permission.name === 'aluno');
    const profileImageSrcRef = useRef<string | null>(null);

    useEffect(() => {
        const cachedImage = localStorage.getItem("profileImage");
        if (cachedImage) {
            profileImageSrcRef.current = cachedImage;
        } else if (userEditing?.fotoPerfilServer?.data) {
            const base64Image = `data:image/png;base64,${userEditing.fotoPerfilServer.data}`;
            profileImageSrcRef.current = base64Image;
            localStorage.setItem("profileImage", base64Image); 
        }
    }, [userEditing?.fotoPerfilServer?.data]);

    const [selectedParticipantId, setSelectedParticipantId] = useState<number | null>(
        null
    );

    const handleCheckboxChange = (participantId: number) => {
        setSelectedParticipantId(selectedParticipantId === participantId ? null : participantId);
    };

    const handleButtonClick = (action: () => void) => {
        if (participantId === null) {
            toast.error(`Por favor, selecione um ${getProfile()}.`, {
                theme: theme.id !== 2 ? "light" : "dark"
            });
        } else {
            action();
        }
    };

    const handleDeleteClick = () => {
        dialogConfirm({
            text: `Você tem certeza que deseja excluir este ${getProfile()}?`,
            title: `Excluir ${getProfile()}`,
            onClickConfirm: () => {
                console.log(`${getProfile()} ${participantId} excluído!`);
            }
        });
    };

    const handleEditClick = useCallback(() => {
        if (selectedParticipantId !== null) {
            const selectedParticipant = profiles.find(profile => profile.id === selectedParticipantId);

            if (selectedParticipant) {
                navigate(`/courseenvironment/${courseId}/participants/${participantsAttribute}/edit/${selectedParticipantId}`, {
                    state: {
                        courseId: courseId,
                        participantId: selectedParticipantId,
                        participantName: selectedParticipant.nome,
                        participantCpf: selectedParticipant.cpf,
                        participantDre: selectedParticipant.dre
                    }
                });
            }
        } else {
            toast.error(`Por favor, selecione um ${getProfile()}.`, {
                theme: theme.id !== 2 ? "light" : "dark"
            });
        }
    }, [navigate, courseId, selectedParticipantId, participantsAttribute, profiles, theme]);

    const buttons = [
        { label: "Inserir", onClick: () => navigate(`/courseenvironment/${courseId}/participants/${participantsAttribute}/new`) },
        {
            label: "Editar",
            onClick: handleEditClick
        },
        {
            label: "Excluir",
            onClick: () => handleButtonClick(handleDeleteClick)
        }
    ];

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Participantes</span>
                <span>{getTitle()}</span>
            </Breadcrumb>
            <Title>Participantes</Title>
            <Title>{getTitle()}</Title>
            <SearchContainer>
                <Buttons>
                    <Button
                        className={getProfile() === 'PROFESSOR' ? 'active' : ''}
                        style={{ minWidth: "145px", borderRadius: "20px" }}
                        btnTheme="primary"
                        onClick={handleTeacher}
                    >
                        Professor(a)
                    </Button>
                    <Button
                        className={getProfile() === 'ALUNO' ? 'active' : ''}
                        style={{ minWidth: "145px", borderRadius: "20px" }}
                        btnTheme="primary"
                        onClick={handleStudents}
                    >
                        Alunos
                    </Button>
                    <Button
                        className={getProfile() === 'COLABORADOR' ? 'active' : ''}
                        style={{ minWidth: "145px", borderRadius: "20px" }}
                        btnTheme="primary"
                        onClick={handleCollaborators}
                    >
                        Colaboradores
                    </Button>
                </Buttons>
                {getProfile() !== 'PROFESSOR' ? (
                    <SearchInputContainer>
                        <SearchContent>
                            <SearchIcon>
                                <FaSearch />
                            </SearchIcon>
                            <SearchInput type="text" placeholder="Buscar participante" />
                        </SearchContent>
                        {!isStudent && (
                            <Buttons>
                                {buttons.map((button, index) => (
                                    <Button
                                        key={index}
                                        btnTheme="primary"
                                        style={{ minWidth: "145px" }}
                                        onClick={button.onClick}
                                    >
                                        {button.label}
                                    </Button>
                                ))}
                            </Buttons>
                        )}
                    </SearchInputContainer>
                ) : (
                    null
                )}
            </SearchContainer>

            {getProfile() !== 'PROFESSOR' ? (
                <Content>
                    {profiles.length > 0 ? (
                        profiles.map((profile) => (
                            profile.nome && (
                                <TableRow key={profile.id}>
                                    <TableCell>
                                        {!isStudent && (
                                            <input
                                                type="checkbox"
                                                id="edit-student-checkbox"
                                                checked={selectedParticipantId === profile.id}
                                                onChange={() => handleCheckboxChange(profile.id)}
                                            />
                                        )}
                                        <span>{profile.nome}</span>
                                    </TableCell>
                                    {getProfile() === 'ALUNO' ? (
                                        <TableCell key={`${profile.id}-birthdate`} style={{ display: "flex", justifyContent: "space-around" }}>
                                            <FormattedDateBr date={profile.dataNascimento} />
                                        </TableCell>
                                    ) : getProfile() === 'COLABORADOR' && isStudent ? (
                                        <TableCell key={`${profile.id}-birthdate`} style={{ display: "flex", justifyContent: "space-around" }}>
                                            <FormattedDateBr date={profile.dataNascimento} />
                                            {profile.dataNascimento}
                                        </TableCell>
                                    ) : null}
                                    {getProfile() === 'ALUNO' ? (
                                        <TableCell key={`${profile.id}-points`} style={{ display: "flex", justifyContent: "space-around" }}>
                                            <span>88,0 Pontos</span>
                                        </TableCell>
                                    ) : getProfile() === 'COLABORADOR' ? (
                                        <TableCell key={`${profile.id}-role`} style={{ display: "flex", justifyContent: "space-around" }}>
                                            <span>Monitor(a)</span>
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            )
                        ))
                    ) : (
                        <p>Nenhum participante encontrado.</p>
                    )}

                    <Pagination>
                        <span>Página</span>
                        <a href="#" style={{ color: "white", backgroundColor: "#949494" }}>
                            &lt;
                        </a>
                        <span>01</span>
                        <span>de</span>
                        <span>01</span>
                        <a href="#" style={{ color: "white", backgroundColor: "#F34F4F" }}>
                            &gt;
                        </a>
                    </Pagination>
                </Content>
            ) : (
                <Content>
                    {profiles.length > 0 ? (
                        profiles.map((profile) => (
                            profile.nome && (
                                <Infos key={profile.id}>
                                    <ImageTextContainer>
                                        <MemoizedProfileImage src={profileImageSrcRef.current} />
                                        <TextContainer>
                                            <div style={{ marginBottom: "40px" }}>
                                                <span style={{ fontWeight: "700", marginRight: "10px" }}>
                                                    Nome:
                                                </span>{" "}
                                                {profile.nome}
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "700", marginRight: "10px" }}>
                                                    E-mail:
                                                </span>{" "}
                                                {profile.email}
                                            </div>
                                        </TextContainer>
                                    </ImageTextContainer>
                                </Infos>
                            )
                        ))
                    ) : (
                        <p>Nenhum participante encontrado.</p>
                    )}
                </Content>
            )}
        </Container >
    );
};

export default ParticipantsRead;
