import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout';
import Admin from '../pages/Admin';
import EditForm from '../pages/Admin/EditForm';
import AddForm from '../pages/Admin/AddForm';
import CourseCreate from '../pages/Course/CourseCreate';
import CourseEdit from '../pages/Course/CourseEdit';
import CourseRead from '../pages/Course/CourseRead';
import CourseRegistration from '../pages/CourseRegistration';
import MyCourses from '../pages/MyCourses';
import ForumPage from '../pages/Communication/Forum';
import NewPost from '../pages/Communication/Forum/NewPost';
import PostPage from '../pages/Communication/Forum/PostPage';
import CommentPage from '../pages/Communication/Forum/CommentPage';
import ReplyPage from '../pages/Communication/Forum/ReplyPage';
import AnnouncementsPage from '../pages/Communication/Announcements';
import Announcement from '../pages/Communication/Announcements/Announcement';
import Glossary from '../pages/Query/Glossary';
import GlossaryEntry from "../pages/Query/Glossary/GlossaryEntry";
import Bibliography from '../pages/Query/Bibliography';
import BibliographyEntry from '../pages/Query/Bibliography/BibliographyEntry';
import Resources from '../pages/Resources';
import ResourcePage from '../pages/Resources/ResourcePage';
import ResourceForm from '../pages/Resources/ResourceForm';
import Activities from '../pages/Resources/Activities';
import NewActivity from "../pages/Resources/NewActivity";
import Questionnaire from "../pages/Resources/Activities/Questionnaire";
import Instruction from "../pages/Resources/Activities/Instruction";
import Wiki from "../pages/Resources/Activities/Wiki";
import Meeting from "../pages/Resources/Activities/Meeting";
import Quiz from "../pages/Resources/Activities/Quiz";
import Documents from "../pages/Resources/Activities/Documents";
import Transcript from '../pages/Transcript';

// import Home from '../pages/Home';
// import MyData from '../pages/MyData';
import SpinnerScreen from '../components/SpinnerScreen';
import { useDropdown } from '../shared/contexts/domains/DropdownContext';
import { useUser } from '../shared/contexts/UserContext';
import { useAuth } from '../shared/contexts/Auth';
import SiteMap from '../pages/SiteMap';
import Accessibility from '../pages/Accessibility';
import UpdatePassword from '../pages/MyData/UpdatePassword';
import ModuleEdit from '../pages/Module/ModuleEdit';
import ParticipantsRead from '../pages/Participants/ParticipantsRead';
import ParticipantsEdit from '../pages/Participants/ParticipantsEdit';

const AppRoutes: React.FC = () => {
    const { checkCompleteRegistration } = useUser();
    const { hasPermission } = useAuth();
    const {
        fetchRegions,
        // fetchUFs, ufs,
        fetchCities,
        fetchKnowledgeAreas,
        fetchLevels,
        fetchCourseModuleTypes,
        fetchCourseModalities,
    } = useDropdown();
    const Home = lazy(() => import("../pages/Home"));
    const MyData = lazy(() => import('../pages/MyData'));
    // const CourseCreate = lazy(() => import("../pages/Course/CourseCreate"));
    // const CourseEdit = lazy(() => import("../pages/Course/CourseEdit"));
    // const CourseRead = lazy(() => import("../pages/Course/CourseRead"));
    const CourseEnvironment = lazy(() => import("../pages/CourseEnvironment"));
    const Module = lazy(() => import("../pages/Module/ModuleRead"));

    useEffect(() => {
        checkCompleteRegistration();
    }, []);

    useEffect(() => {
        fetchRegions();
        // fetchUFs();
        fetchCities();
        fetchKnowledgeAreas();
        fetchLevels();
        fetchCourseModuleTypes();
        fetchCourseModalities();
    }, []);

    const permission = hasPermission();

    return (
        <Layout>
            <Routes>
                {/* <Route path="/" element={<Home/>} /> */}
                <Route path="/" element={
                    <Suspense fallback={<SpinnerScreen />}>
                        <Home/>
                    </Suspense>
                }/>
                <Route path="/admin" element={<Admin/>} />
                <Route path="/admin/edit/:id" element={<EditForm/>} />
                {/* <Route path="/mydata" element={<MyData/>} /> */}
                <Route path="/mydata" element={
                    <Suspense fallback={<SpinnerScreen />}>
                        <MyData/>
                    </Suspense>
                }/>
                {hasPermission() && <Route path="/course" element={<CourseCreate/>} />}
                {/* {hasPermission() && (
                    <Route path="/course" element={
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <CourseCreate/>
                        </Suspense>
                    }/>
                )} */}
                {hasPermission() && <Route path="/course/edit/:codigo" element={<CourseEdit/>} />}
                {/* {hasPermission() && (
                    <Route path="/course/edit/:codigo" element={
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <CourseEdit/>
                        </Suspense>
                    }/>
                )} */}
                {!hasPermission() && <Route path="/course/read/:codigo" element={<CourseRead/>} />}
                {/* {!hasPermission() && (
                    <Route path="/course/read/:codigo" element={
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <CourseRead/>
                        </Suspense>
                    }/>
                )} */}
                <Route path="/courseenvironment/:codigo" element={
                    <Suspense fallback={<SpinnerScreen/>}>
                        <CourseEnvironment />
                    </Suspense>
                } />
                <Route path="/courseenvironment/:codigo/module/:id" element={
                    <Suspense fallback={<SpinnerScreen/>}>
                        <Module />
                    </Suspense>
                } />
                {hasPermission() && <Route path="/courseenvironment/:codigo/module/edit/:id" element={<ModuleEdit mode={'edit'}/>} />}
                <Route path="/courseenvironment/:codigo/participants/students" element={<ParticipantsRead />} />
                <Route path="/courseenvironment/:codigo/participants/students/new" element={<ParticipantsEdit mode="new" />} />
                <Route path="/courseenvironment/:codigo/participants/students/edit/:id" element={<ParticipantsEdit mode={'edit'}/>} />
                <Route path="/courseenvironment/:codigo/participants/collaborators" element={<ParticipantsRead />} />
                <Route path="/courseenvironment/:codigo/participants/collaborators/new" element={<ParticipantsEdit mode="new" />} />
                <Route path="/courseenvironment/:codigo/participants/collaborators/edit/:id" element={<ParticipantsEdit mode={'edit'}/>} />
                <Route path="/courseenvironment/:codigo/participants/teacher" element={<ParticipantsRead />} />
                <Route path="/mycourses" element={<MyCourses/>} />
                <Route path="/sitemap" element={<SiteMap/>} />
                <Route path="/accessibility" element={<Accessibility/>} />
                <Route path="/updatepassword/:id" element={<UpdatePassword/>} />
                <Route path="/courseenvironment/:codigo/communication/forum" element={<ForumPage />} />
                <Route path="/courseenvironment/:codigo/communication/forum/post" element={<NewPost />} />
                <Route path="/courseenvironment/:codigo/communication/forum/post/:postId" element={<PostPage />} />
                <Route
                    path="/courseenvironment/:codigo/communication/forum/comment/:postId"
                    element={<CommentPage />}
                />
                <Route
                    path="/courseenvironment/:codigo/communication/forum/reply/:postId/:commentId"
                    element={<ReplyPage />}
                />
                <Route
                    path="/courseenvironment/:codigo/communication/announcements"
                    element={<AnnouncementsPage hasPermission={permission} />}
                />
                <Route
                    path="/courseenvironment/:codigo/communication/announcements/new"
                    element={<Announcement mode="new" />}
                />
                <Route
                    path="/courseenvironment/:codigo/communication/announcements/edit/:id"
                    element={<Announcement mode="edit" />}
                />
                <Route path="/courseenvironment/:codigo/query/glossary" element={<Glossary />} />
                <Route path="/courseenvironment/:codigo/query/glossary/new" element={<GlossaryEntry mode="new" />} />
                <Route
                    path="/courseenvironment/:codigo/query/glossary/edit/:id"
                    element={<GlossaryEntry mode="edit" />}
                />

                <Route
                    path="/courseenvironment/:codigo/query/bibliography"
                    element={<Bibliography />}
                />
                <Route
                    path="/courseenvironment/:codigo/query/bibliography/new"
                    element={<BibliographyEntry mode="new" />}
                />
                <Route
                    path="/courseenvironment/:codigo/query/bibliography/edit/:id"
                    element={<BibliographyEntry mode="edit" />}
                />
                <Route path="/courseenvironment/:codigo/resources" element={<Resources />} />
                <Route path="/courseenvironment/:codigo/resources/:resourceId" element={<ResourcePage />} />

                <Route path="/courseenvironment/:codigo/resources/new" element={<ResourceForm mode="new" />} />
                <Route path="/courseenvironment/:codigo/resources/edit/:id" element={<ResourceForm mode="edit" />} />
                <Route path="/courseenvironment/:codigo/resources/activities" element={<Activities/>} />
                <Route path="/courseenvironment/:codigo/resources/activities/new" element={<NewActivity />} />

                {/* Páginas de atividade */}
                <Route
                    path="/courseenvironment/:codigo/resources/activities/questionnaire/:id"
                    element={<Questionnaire />}
                />
                <Route
                    path="/courseenvironment/:codigo/resources/activities/instruction/:id"
                    element={<Instruction />}
                />
                <Route path="/courseenvironment/:codigo/resources/activities/wiki/:id" element={<Wiki />} />
                <Route path="/courseenvironment/:codigo/resources/activities/meeting/:id" element={<Meeting />} />
                <Route path="/courseenvironment/:codigo/resources/activities/quiz/:id" element={<Quiz />} />
                <Route path="/courseenvironment/:codigo/resources/activities/documents/:id" element={<Documents />} />

                <Route path="/courseenvironment/:codigo/transcript" element={<Transcript />} />

                {hasPermission() && <Route path="/register" element={<AddForm/>} />}

                {/* FIXME: Essa página abre quando o aluno clica no
                botão "Solicitar Matricula" na apresentação do curso
                não ingressado. O botão é condicional e aparece
                somente quando (1) o usuário for aluno e (2) a
                matricula estiver aberta. */}
                {hasPermission() && (
                    <Route
                        path="/courseenvironment/:codigo/course-registration"
                        element={<CourseRegistration />}
                    />
                )}

                <Route path="*" element={<Navigate to={"/"}/>} />
            </Routes>
        </Layout>
    );
};

export default AppRoutes;
