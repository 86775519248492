import React from "react";
import ActivityPage from "../ActivityPage";

const Meeting = () => {
    return (
        <ActivityPage
            activityType="Encontros"
            dataKey="meetings"
            user="Patrícia Zudio"
            announcement="Você ainda tem que fazer o teste online!"
        />
    );
};

export default Meeting;
