import styled from "styled-components";

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 20px;
    text-align: right;
`;
