import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    line-height: 1.4;

    span {
        display: flex;
        gap: 8px;
        color: ${props => props.theme.colors.labelText};
        text-align: start;

        &:not(span:first-child) {
            margin-left: 8px;
            margin-right: 0;
        }

        &:not(span:first-child):before {
            content: '>';
        }

        > button {
            font-size: inherit;
            font-weight: 400;
            color: ${props => props.theme.colors.linkText};
            text-decoration: ${props => props.theme.id === 2 ? 'underline' : 'none'};

            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    /* @media (max-width: 576px) {
        margin-bottom: 40px;
    } */
`;