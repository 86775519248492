import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Container,
    SubTitle,
    Input,
    FormGroup,
    TableContainer,
    LeftColumn,
    RightColumn,
    Label,
    Buttons,
    EditorFormGroup
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import InputSelectWrapper from "../../../components/InputSelectWrapper";
import Editor from "../../../components/Editor";
import { DomainSelectOption } from "../../../shared/models/domains/Domains";

interface GlossaryEntryProps {
    mode: "new" | "edit";
}

interface LocationState {
    entry: {
        name: string;
        definition: string;
    };
}

const GlossaryEntry: React.FC<GlossaryEntryProps> = ({ mode }) => {
    const [name, setName] = useState("");
    const [definition, setDefinition] = useState("");
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;

    useEffect(() => {
        if (mode === "edit" && location.state) {
            const state = location.state as LocationState;
            if (state.entry) {
                setName(state.entry.name);
                setDefinition(state.entry.definition);
            }
        }
    }, [mode, location.state]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleDefinitionChange = (content: string) => {
        setDefinition(content);
    };

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}/query/glossary`);
    };

    const courseModules: DomainSelectOption[] = [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        { value: "3", label: "C" }
    ];

    const handleSave = () => {
        const payload = {
            name,
            definition
        };

        if (mode === "new") {
            fetch("/api/glossary", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(() => {
                navigate(`/courseenvironment/${courseId}/query/glossary`);
            });
        } else {
            fetch(`/api/glossary/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(() => {
                navigate(`/courseenvironment/${courseId}/query/glossary`);
            });
        }
    };

    const inputSelectStyle = {
        control: (provided: React.CSSProperties) => ({
            ...provided,
            borderRadius: "2px",
            border: "2px solid var(--secondary)"
        }),
        placeholder: (provided: React.CSSProperties) => ({
            ...provided,
            color: "#141414"
        })
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Consulta</span>
                <span>Glossário</span>
                <span>{mode === "new" ? "Inserir Termo" : "Editar Termo"}</span>
            </Breadcrumb>
            <Title>Glossário</Title>
            <SubTitle>
                {mode === "new" ? "Inserir Termo" : "Editar Termo"}
            </SubTitle>

            <FormGroup>
                <Label htmlFor="entry-name" style={{ fontWeight: "700" }}>Termo:</Label>
                <Input
                    id="entry-name"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Digite o nome do termo"
                />
            </FormGroup>

            <FormGroup>
                <TableContainer>
                    <LeftColumn>
                        <p style={{ fontWeight: "700" }}>Associação:</p>
                    </LeftColumn>
                    <RightColumn>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <FormGroup>
                            <Label htmlFor="notice">Aviso:</Label>
                            <Input
                                id="notice"
                                type="text"
                                placeholder="Aviso..."
                            />
                        </FormGroup>
                    </RightColumn>
                </TableContainer>
            </FormGroup>

            <EditorFormGroup>
                <Label htmlFor="entry-definition" style={{ fontWeight: "700" }}>Conteúdo:</Label>
                <Editor
                    content={definition}
                    onChange={handleDefinitionChange}
                />
            </EditorFormGroup>

            <Buttons>
                <Button type="submit" btnTheme="primary" onClick={handleSave}>
                    Salvar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

export default GlossaryEntry;
