import styled from "styled-components";

interface ImageProps {
    src: string;
    alt: string;
}

export const Container = styled.div`
    max-width: 600px;
    margin: 0 auto;
    // border: 1px solid;

    .slick-prev {
        left: -65px;
    }

    .slick-next {
        right: -55px;
    }

    .slick-prev,
    .slick-next {
        height: 50px;
        width: 50px;
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }
`;

export const Image = styled.img<ImageProps>`
    width: 100%;
    height: auto;
`;

export const SlickArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    color: var(--secondary);

    svg {
        stroke-width: 1px;
        transform: scale(1, 0.8);
    }

    &:hover {
        color: gray;
    }
`;
