export interface Course {
    code: number;
    name: string;
}

export interface Resource {
    id: number;
    title: string;
    type: string;
    course: string;
    AddedBy: string;
    AddedOn: string;
    description: string;
    courses?: string[] | Course[]; // Aceitar arrays de strings ou objetos Course
    announcement?: string;
    attachments?: string[];
}

const descriptionText =
    "Nullam eu ante vel est convallis dignissim. Fusce suscipit, wisi nec facilisis facilisis, est dui fermentum leo, quis tempor ligula erat quis odio. Nunc porta vulputate tellus. Nunc rutrum turpis sed pede. Sed bibendum. Aliquam posuere. Nunc aliquet, augue nec adipiscing interdum, lacus tellus malesuada massa, quis varius mi purus non odio. Pellentesque condimentum, magna ut suscipit hendrerit, ipsum augue ornare nulla, non luctus diam neque sit amet urna. Curabitur vulputate vestibulum lorem.";

export const mockResources: Resource[] = [
    {
        id: 1,
        title: "Imunoglobulina",
        type: "Animação",
        course: "Ver cursos",
        AddedBy: "Jorge H.",
        AddedOn: "16.09.2023",
        description: descriptionText,
        courses: ["Curso 1", "Curso 2", "Curso 3"],
        announcement: `Exemplo de um aviso longo: ${descriptionText}`,
        attachments: ["Imagem Sample 01", "Software Sample 01"]
    },
    {
        id: 2,
        title: "Antígenos 1",
        type: "Imagem",
        course: "Ver cursos",
        AddedBy: "Amélia J.",
        AddedOn: "24.08.2023",
        description: descriptionText,
        courses: ["Curso 1", "Curso 2", "Curso 3"],
        announcement: "Este texto serve de base para o teste.",
        attachments: ["Imagem Sample 01", "Software Sample 01"]
    },
    {
        id: 3,
        title: "Os anticorpos",
        type: "Software",
        course: "Ver cursos",
        AddedBy: "Jorge H.",
        AddedOn: "17.02.2024",
        description: descriptionText,
        courses: [
            {
                code: 78, 
                name: "Curso Anticorpos Monoclonais"
            },
            {
                code: 79,
                name: "Curso Sistema Imunológico"
            },
            {
                code: 80,
                name: "Curso Sistema Reticuloendotelial"
            }           
        ],
        announcement: "Este texto serve de base para o teste.",
        attachments: ["Imagem Sample 01", "Software Sample 01"]
    },
    {
        id: 4,
        title: "Brainstorming",
        type: "Tabela",
        course: "Ver cursos",
        AddedBy: "Josefa H.",
        AddedOn: "15.11.2023",
        description: descriptionText,
        courses: ["Curso 1", "Curso 2", "Curso 3"],
        announcement: "Este texto serve de base para o teste.",
        attachments: ["Imagem Sample 01", "Software Sample 01"]
    }
];
