import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Container,
    Input,
    FormGroup,
    EditorFormGroup,
    TableContainer,
    LeftColumn,
    RightColumn,
    Label,
    Buttons
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import InputSelectWrapper from "../../../components/InputSelectWrapper";
import FileUpload from "../../../components/FileUpload";
import Editor from "../../../components/Editor";
import { DomainSelectOption } from "../../../shared/models/domains/Domains";

interface AnnouncementProps {
    mode: "new" | "edit";
}

const Announcement: React.FC<AnnouncementProps> = ({ mode }) => {
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [announcementContent, setAnnouncementContent] = useState("");
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;

    useEffect(() => {
        if (mode === "edit" && id) {
            // Aqui a gente obtém os detalhes do aviso e preenche o estado
            /* fetch(`/api/announcements/${id}`) */
            fetch(`/announcements.json`)
                .then((response) => response.json())
                .then((data) => {
                    const announcement = data.announcements.find(
                        (ann: { id: number }) => ann.id === parseInt(id)
                    );
                    if (announcement) {
                        setTitle(announcement.title);
                        setLink(announcement.link);
                        setAnnouncementContent(announcement.content);
                    }
                });
        }
    }, [mode, id]);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
    };

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        console.log("Submitting files: ", uploadedFiles);
        console.log("Uploaded files: ", files);
    };

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}/communication/announcements`);
    };

    const courseModules: DomainSelectOption[] = [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        { value: "3", label: "C" }
    ];

    const handleSave = () => {
        const payload = {
            title,
            link,
            content: announcementContent,
            files: uploadedFiles
        };

        if (mode === "new") {
            fetch("/api/announcements", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(() => {
                navigate(`/courseenvironment/${courseId}/communication/announcements`);
            });
        } else {
            fetch(`/api/announcements/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(() => {
                navigate(`/courseenvironment/${courseId}/communication/announcements`);
            });
        }
    };

    const inputSelectStyle = {
        control: (provided: React.CSSProperties) => ({
            ...provided,
            borderRadius: "2px",
            border: "2px solid var(--secondary)"
        }),
        placeholder: (provided: React.CSSProperties) => ({
            ...provided,
            color: "#141414"
        })
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Comunicação</span>
                <span>Aviso</span>
                {mode === "new" && <span>Novo Aviso</span>}
            </Breadcrumb>
            <Title>{mode === "new" ? "Novo Aviso" : "Editar Aviso"}</Title>

            <FormGroup>
                <Label htmlFor="announcement-title" style={{ fontWeight: "700" }}>Título do Aviso:</Label>
                <Input
                    id="announcement-title"
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Digite o título do aviso"
                />
            </FormGroup>

            <FormGroup>
                <TableContainer>
                    <LeftColumn>
                        <p style={{ fontWeight: "700" }}>Associação:</p>
                    </LeftColumn>
                    <RightColumn>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o Curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o Módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a Atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a Comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <FormGroup>
                            <Label htmlFor="notice">Aviso:</Label>
                            <Input
                                id="notice"
                                type="text"
                                placeholder="Este texto serve de base para o teste..."
                            />
                        </FormGroup>
                    </RightColumn>
                </TableContainer>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="announcement-link" style={{ fontWeight: "700" }}>Link:</Label>
                <Input
                    id="announcement-link"
                    type="text"
                    value={link}
                    onChange={handleLinkChange}
                    placeholder="https://link.teste.ex"
                />
            </FormGroup>

            <EditorFormGroup>
                <Editor
                    content={announcementContent}
                    onChange={setAnnouncementContent}
                />
            </EditorFormGroup>

            <Buttons>
                <Button type="submit" btnTheme="primary" onClick={handleSave}>
                    Salvar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

export default Announcement;
