import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Image, SlickArrow } from "./styles";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

interface SimpleCarouselProps {
    images: string[];
}

const SimpleCarousel: React.FC<SimpleCarouselProps> = ({ images }) => {
    const settings = {
        arrows: true,
        // lazyLoad: "ondemand"
        prevArrow: (
            <SlickArrow>
                <FaAngleLeft />
            </SlickArrow>
        ),
        nextArrow: (
            <SlickArrow>
                <FaAngleRight />
            </SlickArrow>
        )
    };

    return (
        <Container>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <Image src={image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </Container>
    );
};

export default SimpleCarousel;
