import styled from "styled-components";
import "react-confirm-alert/src/react-confirm-alert.css";

interface DialogProps {
    bgColor: string;
    children: React.ReactNode;
}

export const Dialog = styled.div<DialogProps>`
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 32px;
    width: 749px;
    background-color: ${(props) => props.theme.colors.bgPrimary};
    border: 1px solid #5b0c0f;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    h1,
    p {
        align-self: center;
    }

    h1 {
        color: ${(props) => props.theme.colors.labelText};
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
    }

    @media (max-width: 576px) {
        width: unset;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;

    button {
        width: 95px;
        font-weight: normal;
        font-size: 16px;
        margin: 0;
    }
`;
