import React from "react";
import ActivityPage from "../ActivityPage";

const Questionnaire = () => {
    return (
        <ActivityPage
            activityType="Questionário"
            dataKey="questionnaires"
            user="Patrícia Zudio"
            announcement="Você ainda tem que fazer o teste online!"
        />
    );
};

export default Questionnaire;