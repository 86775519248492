import styled, { css } from "styled-components";

interface Props {
    btnTheme: "primary" | "secondary" | "link";
}

export const Container = styled.button<Props>`
    ${(props) => {
        if (props.btnTheme === "primary" || props.btnTheme === "secondary")
            return css<Props>`
                width: auto;
                margin: 7px 0;
                padding: 10px;
                font-weight: bold;
                font-size: 16px;
                background-color: ${props.theme.colors.primary};
                color: ${props.theme.colors.white};
                transition: opacity 0.3s;

                &:not(:disabled):hover {
                    opacity: 0.8;
                }
            `;
    }}

    ${(props) => {
        if (props?.btnTheme === "link")
            return css<Props>`
                width: auto;
                color: ${(x) => x.theme.colors.linkText};

                &:hover {
                    text-decoration: underline;
                }
            `;
    }}

    &:disabled {
        background-color: #eee;
        color: #949494;
    }
`;
