import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Container,
    Text,
    Content,
    TableRow,
    TableHeader,
    TableCell,
    Pagination,
    SearchContainer,
    SearchInputContainer,
    SearchIcon,
    SearchInput,
    HoverPopupWrapper,
    AuthorDate
} from "./styles";
import { FaSearch } from "react-icons/fa";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcrumb";
import HoverPopup from "../../../components/HoverPopup";
import FormattedDate from "../../../components/FormattedDate";

interface Comment {
    id: number;
    content: string;
}

interface Post {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
    respostas: number;
    visualizações: number;
    curtidas: number;
    likes: number;
    comments: Comment[];
}

const ForumPage: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;

    useEffect(() => {
        fetch("/mock.json")
            .then((response) => response.json())
            .then((data) => setPosts(data.posts));
    }, []);

    const navigate = useNavigate();
    const handleNewPost = () => {
        navigate(`/courseenvironment/${courseId}/communication/forum/post`);
    };

    const handleRowClick = (postId: number) => {
        navigate(`/courseenvironment/${courseId}/communication/forum/post/${postId}`);
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Comunicação</span>
                <span>Fórum</span>
            </Breadcrumb>

            <Text>
                Seja bem vindo ao Fórum, este espaço é dedicado à troca de
                ideias, aprendizado e conexão.
            </Text>
            <SearchContainer>
                <Button
                    style={{ minWidth: "145px" }}
                    btnTheme="primary"
                    onClick={handleNewPost}
                >
                    Nova postagem
                </Button>
                <SearchInputContainer>
                    <SearchIcon>
                        <FaSearch />
                    </SearchIcon>
                    <SearchInput type="text" placeholder="Buscar tópico" />
                </SearchInputContainer>
            </SearchContainer>

            <Content>
                <TableHeader>
                    <TableCell>Tópicos</TableCell>
                    <TableCell>Respostas</TableCell>
                    <TableCell>Visualizações</TableCell>
                    <TableCell>Curtidas</TableCell>
                </TableHeader>
                {posts.map((post) => (
                    <TableRow
                        key={post.id}
                        onClick={() => handleRowClick(post.id)}
                    >
                        <TableCell>
                            <div>{post.title}</div>
                            <AuthorDate>
                                <span>{post.author}</span>
                                <span>
                                    <FormattedDate dateString={post.date} bold={true} />
                                </span>
                            </AuthorDate>
                        </TableCell>
                        <TableCell><strong>{post.respostas}</strong></TableCell>
                        <TableCell><strong>{post.visualizações}</strong></TableCell>
                        <TableCell>
                            <strong>{post.curtidas}</strong>
                            <HoverPopupWrapper>
                                <HoverPopup linkText="Associações" />
                            </HoverPopupWrapper>
                        </TableCell>
                    </TableRow>
                ))}
                <Pagination>
                    <span>Página</span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        style={{ color: "white", backgroundColor: "#949494" }}
                    >
                        &lt;
                    </a>
                    <span>01</span>
                    <span>de</span>
                    <span>01</span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        style={{ color: "white", backgroundColor: "#F34F4F" }}
                    >
                        &gt;
                    </a>
                </Pagination>
            </Content>
        </Container>
    );
};

export default ForumPage;
