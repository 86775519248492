import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start;
    column-gap: 32px;
    margin-top: 1em;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const SearchInputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-left: 10px;
    flex-grow: 1;
`;

export const SearchIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--gray2);
`;

export const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-left: 10px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 8px 8px 8px 35px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 4px;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const BibliographyItem = styled.div`
    margin: 10px 0;
    padding: 10px;
    border-bottom: 2px solid var(--secondary);
`;

export const BibliographyTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`;

export const BibliographyDetails = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;

    > div:first-child {
        flex: 1;
        flex-basis: 70%;
        min-width: 70%;
        text-align: left;
    }

    > div:nth-child(2) {
        text-align: center;
        flex: 1;
        font-weight: 700;
        flex-basis: 15%;
        min-width: 15%;
        text-align: left;
    }

    > div:last-child {
        flex: 1;
        flex-basis: 15%;
        min-width: 15%;
        text-align: left;
    }
`;

export const Column = styled.div`
    flex: 1;

    div {
        margin: 15px 0;

        &:first-child {
            text-align: left;
            font-weight: bold;

            span {
                color: var(--primary);
            }
        }

        &:not(:first-child) {
            text-align: left;
        }
    }

    a {
        color: blue;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

export const Icons = styled.span`
    cursor: pointer;

    > *:not(:last-child) {
        margin-right: 10px;
    }
`;

export const CommentDelete = styled.span`
    color: var(--secondary);

    &:hover {
        color: var(--gray2);
    }
`;

export const HorizontalFormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    text-align: left;
`;

export const HorizontalLabel = styled.label`
    flex: 0 0 150px;
    margin-right: 10px;
    font-weight: bold;
`;

export const HorizontalInput = styled.input`
    flex: 1;
    padding: 8px;
    font-size: 16px;
    border: 2px solid var(--secondary);

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
    }
`;

export const EditorFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    width: 100%;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
        margin-bottom: 5px;
    }

    .editor {
        width: 100%;
    }
`;

export const TableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const LeftColumn = styled.div`
    flex: 1;
    margin-top: 8em;
    font-weight: 700;

    @media (max-width: 768px) {
        margin-top: 2em;
    }
`;

export const RightColumn = styled.div`
    flex: 1;
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10px;
    text-align: right;
`;

export const PlusIcon = styled.span`
    color: var(--primary);
    margin-left: 10px;
    font-size: 26px;
    cursor: pointer;
`;

export const LinkButton = styled.span`
    display: flex;
    align-items: center;
    color: var(--primary);
    font-size: 26px;
    cursor: pointer;
    margin-left: 8px;

    .text {
        margin-left: 8px;
        font-size: 16px;
        color: ${(props) => props.theme.colors.labelText};
    }
`;

export const SubTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-bottom: 20px;
    color: ${({ theme }) =>
        theme.id === 1 ? theme.colors.titleText : theme.colors.primary};
`;
