export const gradeData = {
    sections: [
        {
            header: "Atividade Realizada na Constructore",
            rows: [
                { atividade: "Pesquisa de Campo I", peso: 2, nota: 2.5 },
                { atividade: "Pesquisa de Campo III", peso: 2, nota: 2.5 },
                { atividade: "Teste Final", peso: 2, nota: 2.5 }
            ]
        },
        {
            header: "Avaliação Adicional",
            rows: [
                { atividade: "Assiduidade", peso: 2, nota: 2.5 },
                { atividade: "Disciplina", peso: 3, nota: 2.5 },
                { atividade: "Participação", peso: 1, nota: 2.5 }
            ]
        }
    ],
    footer: {
        media: "Média",
        mediaValue: 5.4,
        mediaFinal: "Média Final",
        mediaFinalValue: 5.0
    }
};

export const participationData = {
    sections: [
        {
            header: "Avisos",
            items: [
                "Não haverá aula no dia 19.02",
                "Reposiçao de aula no dia 19.02",
                "Aula extra com professor convidado",
                "Observem os textos enviados por e-email"
            ]
        },
        {
            header: "Atividades",
            items: [
                "Exercício 3 para o dia 21.02",
                "Preparar lista de exercícios"
            ]
        },
        {
            header: "Blog",
            items: []
        },
        {
            header: "Fórum",
            items: [
                "Tópico de dúvidas da lista",
                "Uma pequena dúvida",
                "Esclarecimento"
            ]
        }
    ]
};
