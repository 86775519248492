import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Container,
    FormGroup,
    Buttons,
    Content
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Editor from "../../../components/Editor";
import SpinnerScreen from "../../../components/SpinnerScreen";
import { AuthUser } from "../../../shared/models/Auth";

interface ModuleEditProps {
    mode: "edit";
}

interface LocationState {
    courseId: number,
    moduleId: number;
    moduleName: string;
    moduleDescription: string;
}

const ModuleEdit: React.FC<ModuleEditProps> = ({ mode }) => {
    const location = useLocation();
    const state = location.state as LocationState;
    const { courseId, moduleId, moduleName, moduleDescription } = state || {};

    const [user, setUser] = useState<AuthUser | null>(null);
    const [isDescriptionInitialized, setIsDescriptionInitialized] = useState(false);
    const [description, setDescription] = useState("");
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false); // Novo estado para controlar o carregamento do salvamento
    const navigate = useNavigate();
    const isStudent = user?.perfis.some(permission => permission.name === 'aluno');

    useEffect(() => {
        if (!isDescriptionInitialized && moduleId) {
            const content = moduleDescription;
            if (content) {
                setDescription(content);
                setIsDescriptionInitialized(true);
                setIsContentLoaded(true);
            }
        }
    }, [moduleId, isDescriptionInitialized]);

    const handleCancel = () => {
        navigate(`/courseenvironment/${courseId}`);
    };

    const handleSave = async () => {
        setIsSaving(true); // Começa o estado de carregamento de salvamento
        const payload = {
            content: description
        };

        try {
            const response = await fetch(`api/curso_modulos/${moduleId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Erro ao salvar os dados');
            }

            navigate(`/courseenvironment/${courseId}`);
        } catch (error) {
            console.error("Erro ao salvar os dados", error);
        } finally {
            setIsSaving(false); // Finaliza o estado de carregamento de salvamento
        }
    };

    if (!isContentLoaded) {
        return <SpinnerScreen />;
    }

    return (
        <Container>
            <Breadcrumb>
                <span>Módulos</span>
                <span>{moduleName}</span>
            </Breadcrumb>
            <Content>
                <div>
                    <Title>Apresentação</Title>
                    <Buttons>
                        <Button btnTheme="primary" onClick={handleCancel} disabled={isSaving}>
                            Cancelar Edição
                        </Button>
                        <Button type="submit" btnTheme="primary" onClick={handleSave} disabled={isSaving}>
                            {isSaving ? "Salvando..." : "Salvar Edição"}
                        </Button>
                    </Buttons>
                    <FormGroup>
                        <Editor
                            content={description}
                            onChange={setDescription}
                        />
                    </FormGroup>
                </div>
                {!isStudent && (
                    <aside>
                        <h4>Editar Curso</h4>
                        <ul>
                            <li><Link to={`/course/edit/${courseId}`}>Passo 1: Apresentação</Link></li>
                            <li>
                                <Link
                                    to={`/course/edit/${courseId}`}
                                    state={{ prevStep: 2 }} // Corrigido: usando state como uma prop separada
                                >
                                    Passo 2: Inscrição
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/course/edit/${courseId}`}
                                    state={{ prevStep: 3 }} // Corrigido: usando state como uma prop separada
                                >
                                    Passo 3: Recursos
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/course/edit/${courseId}`}
                                    state={{ prevStep: 4 }} // Corrigido: usando state como uma prop separada
                                >
                                    Passo 4: Estrutura
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/course/edit/${courseId}`}
                                    state={{ prevStep: 5 }} // Corrigido: usando state como uma prop separada
                                >
                                    Passo 5: Publicação
                                </Link>
                            </li>
                        </ul>
                    </aside>
                )}
            </Content>
        </Container>
    );
};

export default ModuleEdit;
