import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Container,
    Content,
    Text,
    Pagination,
    AnnouncementContainer,
    AnnouncementHeader,
    HeaderItem,
    IconContainer,
    InsertButton,
    CommentDelete
} from "./styles";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcrumb";
import HoverPopup from "../../../components/HoverPopup";
import FormattedDate from "../../../components/FormattedDate";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";

interface Announcement {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
}

interface AnnouncementsPageProps {
    hasPermission: boolean;
}

const AnnouncementsPage: React.FC<AnnouncementsPageProps> = ({
    hasPermission
}) => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;

    useEffect(() => {
        fetch("/announcements.json")
            .then((response) => response.json())
            .then((data) => {
                if (data && data.announcements) {
                    setAnnouncements(data.announcements);
                }
            });
    }, []);

    const navigate = useNavigate();
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    // const handleRowClick = (announcementId: number) =>
    //     navigate(`/communication/announcements/announcement/${announcementId}`);

    const handleNewAnnouncement = () => {
        navigate(
            `/courseenvironment/${courseId}/communication/announcements/new`
        );
    };

    const handleEditAnnouncement = (announcementId: number) => {
        navigate(
            `/courseenvironment/${courseId}/communication/announcements/edit/${announcementId}`
        );
    };

    const handleDeleteAnnouncement = (announcementId: number) => {
        openConfirmDialog({
            text: "Você tem certeza que deseja excluir este aviso?",
            title: "Excluir aviso",
            onConfirm: () => {
                console.log(`Aviso ${announcementId} excluído!`);
            }
        });
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Comunicação</span>
                <span>Avisos</span>
            </Breadcrumb>

            {hasPermission && (
                <InsertButton>
                    <Button btnTheme="primary" onClick={handleNewAnnouncement}>
                        Inserir
                    </Button>
                </InsertButton>
            )}

            <ConfirmDialog />

            {/* FIXME: Melhorar a responsividade do container */}
            <Content>
                {announcements.map((announcement) => (
                    <AnnouncementContainer key={announcement.id}>
                        <AnnouncementHeader>
                            <HeaderItem>{announcement.title}</HeaderItem>
                            <HeaderItem>{announcement.author}</HeaderItem>
                            <HeaderItem>
                                <FormattedDate
                                    dateString={announcement.date}
                                    bold={true}
                                />
                            </HeaderItem>
                            {!hasPermission && (
                                <HoverPopup
                                    linkText="Associações"
                                    bold={true}
                                />
                            )}
                            {hasPermission && (
                                <>
                                    <HeaderItem>
                                        <HoverPopup
                                            linkText="Associações"
                                            bold={true}
                                        />
                                    </HeaderItem>

                                    <IconContainer>
                                        <FaPencilAlt
                                            onClick={() =>
                                                handleEditAnnouncement(
                                                    announcement.id
                                                )
                                            }
                                        />
                                        <CommentDelete>
                                            <FaTrash
                                                onClick={() =>
                                                    handleDeleteAnnouncement(
                                                        announcement.id
                                                    )
                                                }
                                            />
                                        </CommentDelete>
                                    </IconContainer>
                                </>
                            )}
                        </AnnouncementHeader>
                        <Text>{announcement.content}</Text>
                    </AnnouncementContainer>
                ))}
                <Pagination>
                    <span>Página</span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        style={{ color: "white", backgroundColor: "#949494" }}
                    >
                        &lt;
                    </a>
                    <span>01</span>
                    <span>de</span>
                    <span>01</span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        style={{ color: "white", backgroundColor: "#F34F4F" }}
                    >
                        &gt;
                    </a>
                </Pagination>
            </Content>
        </Container>
    );
};

export default AnnouncementsPage;
