// O objetivo dessse hook é simplificar o gerenciamento do diálogo de
// confirmação (ConfirmAlert), centralizando a lógica de exibição e
// fechamento do popup e facilitando a reutilização do código.

import { useState } from "react";
import ConfirmAlert from "../components/ConfirmAlert";

interface ConfirmDialogOptions {
    text: string;
    title?: string;
    onConfirm: () => void;
}

export const useConfirmDialog = () => {
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [dialogOptions, setDialogOptions] = useState<ConfirmDialogOptions | null>(null);

    const openConfirmDialog = (options: ConfirmDialogOptions) => {
        setDialogOptions(options);
        setIsConfirmDialogOpen(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };

    const ConfirmDialog = () => (
        isConfirmDialogOpen && dialogOptions ? (
            <ConfirmAlert
                text={dialogOptions.text}
                title={dialogOptions.title}
                onClickConfirm={() => {
                    dialogOptions.onConfirm();
                    closeConfirmDialog();
                }}
                onCloseDialog={closeConfirmDialog}
            />
        ) : null
    );

    return {
        openConfirmDialog,
        closeConfirmDialog,
        ConfirmDialog,
    };
};
