import React, { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { FaStar, FaRegStar, FaComment, FaTrash, FaReply } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import Breadcrumb from "../../../components/Breadcrumb";
import profilePic from "../../../assets/img/perfil-vazio.png";
import HoverPopup from "../../../components/HoverPopup";
import Carousel from "../../../components/Carousel";
import FormattedDate from "../../../components/FormattedDate";
import { Header, TitleCell, AuthorDateCell } from "./styles";
import image1 from "../../../assets/img/logo_faperj.jpg";
import image2 from "../../../assets/img/logo_constructore_branco.png";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";

interface Comment {
    id: number;
    author: string;
    date: string;
    content: string;
    replyTo?: number;
    images?: string[];
}

interface Post {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
    respostas: number;
    visualizações: number;
    curtidas: number;
    comments: Comment[];
    images?: string[];
}

const posts: Post[] = [
    {
        id: 1,
        title: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd",
        content:
            "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd? Mauris pharetra et ultrices neque. Adipiscing at in tellus integer feugiat scelerisque varius morbi. Ornare arcu dui vivamus arcu. Mollis nunc sed id semper risus in hendrerit gravida. Praesent elementum facilisis leo vel fringilla. Lorem dolor sed viverra ipsum. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd? Mauris pharetra et ultrices neque. Adipiscing at in tellus integer feugiat scelerisque varius morbi. Ornare arcu dui vivamus arcu. Mollis nunc sed id semper risus in hendrerit gravida. Praesent elementum facilisis leo vel fringilla. Lorem dolor sed viverra ipsum.",
        author: "Diego Xavier",
        date: "2024-01-26T13:43:00",
        respostas: 2,
        visualizações: 87,
        curtidas: 32,
        images: [image1, image2],
        comments: [
            {
                id: 1,
                author: "Diego Xavier",
                date: "2024-01-27T10:15:00",
                content:
                    "Fringilla est ullamcorper eget nulla facilisi etiam dignissim. Maecenas sed enim ut sem viverra aliquet eget. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus? Fringilla est ullamcorper eget nulla facilisi etiam dignissim. Maecenas sed enim ut sem viverra aliquet eget. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus?"
            },
            {
                id: 2,
                author: "Vinicius Ribeiro",
                date: "2024-01-27T11:00:00",
                content:
                    "Egestas diam in arcu cursus euismod. Amet volutpat consequat mauris nunc congue nisi. Sit amet aliquam id diam maecenas ultricies mi eget. Massa enim nec dui nunc mattis enim.",
                replyTo: 1
            }
        ]
    },
    {
        id: 2,
        title: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd",
        content:
            "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd? Mauris pharetra et ultrices neque. Adipiscing at in tellus integer feugiat scelerisque varius morbi. Ornare arcu dui vivamus arcu. Mollis nunc sed id semper risus in hendrerit gravida. Praesent elementum facilisis leo vel fringilla. Lorem dolor sed viverra ipsum. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd? Mauris pharetra et ultrices neque. Adipiscing at in tellus integer feugiat scelerisque varius morbi. Ornare arcu dui vivamus arcu. Mollis nunc sed id semper risus in hendrerit gravida. Praesent elementum facilisis leo vel fringilla. Lorem dolor sed viverra ipsum.",
        author: "Diego Xavier",
        date: "2024-01-26T13:43:00",
        respostas: 1,
        visualizações: 70,
        curtidas: 32,
        images: [],
        comments: [
            {
                id: 3,
                author: "Vinicius Ribeiro",
                date: "2024-01-27T12:30:00",
                content:
                    "Fringilla est ullamcorper eget nulla facilisi etiam dignissim. Maecenas sed enim ut sem viverra aliquet eget. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus?"
            }
        ]
    }
];

// As respostas são adicionadas em ordem, sempre abaixo da última.
// Quando se responde ao comentário, adiciona-se o highlight.

const PostPage: React.FC = () => {
    const { postId } = useParams<{ postId: string }>();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;
    const post = posts.find((p) => p.id === parseInt(postId || "", 10));
    const navigate = useNavigate();
    const [likeCount, setLikeCount] = useState(3);
    const [isFavorited, setIsFavorited] = useState(false);
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    if (!post) {
        return <div>Nenhuma postagem encontrada</div>;
    }

    const handleLikeClick = () => {
        setLikeCount(likeCount + 1);
        console.log("Liked");
    };

    const handleCommentClick = () => {
        navigate(`/courseenvironment/${courseId}/communication/forum/comment/${postId}`);
    };

    const handleReplyClick = (commentId: number) => {
        navigate(`/courseenvironment/${courseId}/communication/forum/reply/${postId}/${commentId}`);
    };

    const handleTrashClick = (id: number) => {
        openConfirmDialog({
            text: "Você tem certeza que deseja excluir este comentário?",
            title: "Excluir comentário",
            onConfirm: () => {
                console.log("Deleted comment", id);
            }
        });
    };

    const handleStarClick = () => {
        setIsFavorited((prev) => !prev);
    };

    const getQuote = (id: number) => {
        const comment = post.comments.find((c) => c.id === id);
        return comment
            ? { content: comment.content, author: comment.author }
            : { content: "", author: "" };
    };

    // Trunca respostas com mais de 280 caracteres
    const truncate = (text: string) => {
        const maxLength = 280;
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + "...";
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Comunicação</span>
                <span>Fórum</span>
            </Breadcrumb>

            <Header>
                <TableRow>
                    <TitleCell>{post.title}</TitleCell>
                    <TableCell>Respostas</TableCell>
                    <TableCell>Visualizações</TableCell>
                    <TableCell>Curtidas</TableCell>
                    <TableCell>
                        {/* O botão fecha os comentários, mas o tópico
                        permanece no banco de tópicos */}
                        <Button>Encerrar o Tópico</Button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <AuthorDateCell>
                        {post.author}{" "}
                        <FormattedDate dateString={post.date} bold={true} />
                    </AuthorDateCell>
                    <TableCell>{post.respostas}</TableCell>
                    <TableCell>{post.visualizações}</TableCell>
                    <TableCell>{post.curtidas}</TableCell>
                    <TableCell />
                </TableRow>
            </Header>
            <ContentWrapper>
                <PostSection>
                    <PostContainer>
                        <PostContent>
                            <p>{post.content}</p>
                        </PostContent>
                        <IconsContainer>
                            <StarIcon onClick={handleStarClick}>
                                {isFavorited ? <FaStar /> : <FaRegStar />}
                            </StarIcon>
                            <LikeIcon />
                            <CommentIcon onClick={handleCommentClick} />
                        </IconsContainer>
                    </PostContainer>
                    <HoverPopup bold={true} linkText="Associações" position="right" />
                    {post.images && post.images.length > 0 && (
                        <Carousel images={post.images} />
                    )}
                    {/* A legenda é o nome do arquivo?  */}
                    {/* Image-Sample-01 */}

                    {/* TODO: As imagens são exibidas no carrossel.
                  Qualquer outro anexo deve ser exibido aqui. */}

                    {/* <FileList> */}
                    {/*     {post.attachments && */}
                    {/*         // TODO: filtrar arquivos que não forem imagens */}
                    {/*         post.attachments.map((attachment, index) => ( */}
                    {/*             <FileItem key={attachment}> */}
                    {/*                 <FileNumber>{index + 1}.</FileNumber> */}
                    {/*                 {attachment} */}
                    {/*             </FileItem> */}
                    {/*         ))} */}
                    {/* </FileList> */}

                    <FileList>
                        <FileItem>
                            <FileNumber>2.</FileNumber>
                            Document PDF Sample 01
                        </FileItem>
                    </FileList>
                </PostSection>
            </ContentWrapper>
            <CommentsSection>
                {post.comments.map((comment) => (
                    <CommentContainer key={comment.id}>
                        <ProfilePic src={profilePic} alt="Profile" />
                        <CommentContent>
                            <CommentHeader>
                                <CommentAuthor>{comment.author}</CommentAuthor>
                                <CommentDelete>
                                    <FaTrash
                                        onClick={() =>
                                            handleTrashClick(comment.id)
                                        }
                                    />
                                </CommentDelete>
                            </CommentHeader>
                            {comment.replyTo && (
                                <>
                                    <ReplyToText>
                                        Respondendo a{" "}
                                        {getQuote(comment.replyTo).author}:
                                    </ReplyToText>
                                    <QuotedComment>
                                        {truncate(
                                            getQuote(comment.replyTo).content
                                        )}
                                    </QuotedComment>
                                </>
                            )}
                            <p>{comment.content}</p>
                            <CommentFooter>
                                <CommentDate>
                                    <FormattedDate dateString={post.date} bold={true} />
                                </CommentDate>
                                <CommentIcons>
                                    <ReplyIcon
                                        onClick={() =>
                                            handleReplyClick(comment.id)
                                        }
                                    />
                                    <span>
                                        <LikeNumber>{likeCount}</LikeNumber>{" "}
                                        <LikeIcon onClick={handleLikeClick} />
                                    </span>
                                </CommentIcons>
                            </CommentFooter>
                        </CommentContent>
                    </CommentContainer>
                ))}
            </CommentsSection>
            <ConfirmDialog />
        </Container>
    );
};

const Container = styled.div`
    padding: 20px;
`;

const ContentWrapper = styled.div`
    border-left: 2px solid;
    border-right: 2px solid;
    border-bottom: 2px solid;
`;

const TableRow = styled.div`
    display: table-row;
`;

const TableCell = styled.div`
    display: table-cell;
    text-align: center;
    padding: 2px 10px;
    font-weight: bold;

    &:first-child {
        padding-bottom: 10px;
    }
`;

const PostSection = styled.div`
    padding: 10px;
`;

const PostContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 1.35;
`;

const PostContent = styled.div`
    flex-grow: 1;
    font-weight: bold;
`;

const IconsContainer = styled.div`
    display: flex;
    font-size: 28px;
    gap: 20px;
    color: ${(props) => props.theme.colors.primary};
    align-self: flex-start;
    margin-left: 10px;
`;

const CommentsSection = styled.div`
    margin-top: 10px;
`;

const CommentContainer = styled.div`
    display: flex;
    padding: 15px 0;
    border-bottom: 2px solid;
    text-align: left;
`;

const ProfilePic = styled.img`
    width: 75px;
    height: 75px;
    margin-right: 15px;
`;

const CommentContent = styled.div`
    flex-grow: 1;
`;

const CommentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: bold;
`;

const CommentAuthor = styled.div`
    flex: 1;
    margin-bottom: 1em;
`;

const CommentDelete = styled.div`
    flex: 1;
    text-align: right;
    cursor: pointer;
    margin-left: auto;
    color: var(--secondary);

    &:hover {
        color: var(--gray2);
    }
`;

const CommentFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CommentDate = styled.div`
    margin-top: 1em;
`;

const CommentIcons = styled.div`
    display: inline-flex;
    gap: 25px;
    margin-top: 1em;
    color: ${(props) => props.theme.colors.primary};
`;

const LikeNumber = styled.span`
    position: relative;
    top: -2px;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 50px;
`;

const ReplyToText = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
`;

const QuotedComment = styled.div`
    background-color: var(--primary-variation7);
    color: #000;
    padding: 4px;
    margin-bottom: 10px;
`;

const CommentIcon = styled(FaComment)`
    cursor: pointer;
`;

const LikeIcon = styled(AiFillLike)`
    cursor: pointer;
`;

const ReplyIcon = styled(FaReply)`
    cursor: pointer;
`;

const StarIcon = styled.div`
    cursor: pointer;
    font-size: 24px;
    color: ${(props) => props.theme.colors.primary};
`;

const Button = styled.button`
    position: relative;
    top: 14px;
    width: auto;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: ${(props) => props.theme.colors.bgPrimary};
    color: ${(props) => props.theme.colors.primary};
    transition: opacity 0.3s;

    &:not(:disabled):hover {
        opacity: 0.8;
    }

    &:disabled {
        background-color: #eee;
        color: #949494;
    }
`;

const FileList = styled.ul`
    margin: 15px 10px;
    list-style: none;
`;

const FileItem = styled.li`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: var(--primary);
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
`;

const FileNumber = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

export default PostPage;
