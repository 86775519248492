export interface ActivitiesData {
    title: string;
    description?: string;
}

const sampleText =
    "Pellentesque dapibus suscipit ligula. Donec posuere augue in quam. Etiam vel tortor sodales tellus ultricies commodo. Suspendisse potenti. Aenean in sem ac leo mollis blandit. Donec neque quam, dignissim in, mollis nec, sagittis eu, wisi. Phasellus lacus. Etiam laoreet quam sed arcu. Phasellus at dui in ligula mollis ultricies. Integer placerat tristique nisl. Praesent augue. Fusce commodo. Vestibulum convallis, lorem a tempus semper, dui dui euismod elit, vitae placerat urna tortor vitae lacus.";

export const activitiesData: {
    questionnaires: Record<number, ActivitiesData>;
    instructions: Record<number, ActivitiesData>;
    wikis: Record<number, ActivitiesData>;
    meetings: Record<number, ActivitiesData>;
    quizes: Record<number, ActivitiesData>;
    documents: Record<number, ActivitiesData>;
} = {
    questionnaires: {
        8: {
            title: "Questionário 1 ",
            description: sampleText
        },
        2: {
            title: "Questionário 2",
            description: sampleText
        }
    },
    instructions: {
        5: {
            title: "Orientação",
            description: sampleText
        }
    },
    wikis: {
        7: {
            title: "Wiki 1",
            description: sampleText
        },
        1: {
            title: "Wiki 2",
            description: sampleText
        }
    },
    meetings: {
        3: {
            title: "Encontro 2",
            description: sampleText
        },
        9: {
            title: "Encontro 1",
            description: sampleText
        }
    },
    quizes: {
        4: {
            title: "Teste Online",
            description: sampleText
        }
    },
    documents: {
        6: {
            title: "Envio de Arquivos",
            description: sampleText
        }
    }
};
