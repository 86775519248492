import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import styled from "styled-components";
import {
    Container,
    SubTitle,
    Input,
    FormGroup,
    Label,
    Buttons,
    TableContainer,
    RightColumn,
    LeftColumn,
    AttachmentsWrapper,
    ProgressBarWrapper
} from "./styles";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import Button from "../../components/Button";
import InputTextarea from "../../components/InputTextarea";
import InputSelectWrapper from "../../components/InputSelectWrapper";
import ProgressBar from "../../components/ProgressBar";
import { DomainSelectOption } from "../../shared/models/domains/Domains";
import { mockResources } from "./mockResources";
import Select from "react-select";
import FileUpload from "../../components/FileUpload";

interface ResourceFormProps {
    mode: "new" | "edit";
}

const ResourceForm: React.FC<ResourceFormProps> = ({ mode }) => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { codigo } = useParams<{ codigo: string }>();
    const courseId  = codigo;

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [newType, setNewType] = useState("");
    const [isNewTypeChecked, setIsNewTypeChecked] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const currentUsage = 47.6;
    const totalUsage = 100;

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        console.log("Uploaded files: ", files);
    };

    useEffect(() => {
        if (mode === "edit" && id) {
            const resource = mockResources.find(
                (res) => res.id === parseInt(id)
            );
            if (resource) {
                setTitle(resource.title);
                setDescription(resource.description);
                setType(resource.type);
            }
        }
    }, [mode, id]);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(event.target.value);
    };

    const handleNewTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewType(event.target.value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsNewTypeChecked(e.target.checked);
    };

    const handleCancel = () => {
        // TODO: adicionar confirmação
        navigate(`/courseenvironment/${courseId}/resources`);
    };

    const handleSave = () => {
        const payload = {
            title,
            description,
            type: isNewTypeChecked && newType ? newType : type
        };

        const method = mode === "new" ? "POST" : "PUT";
        const url = mode === "new" ? "/api/resources" : `/api/resources/${id}`;

        fetch(url, {
            method,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        }).then(() => {
            navigate(`/courseenvironment/${courseId}/resources`);
        });
    };

    const resourceTypes = [
        { label: "Animação", value: "Animação" },
        { label: "Imagem", value: "Imagem" },
        { label: "Software", value: "Software" },
        { label: "Tabela", value: "Tabela" }
    ];

    const courseModules: DomainSelectOption[] = [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        { value: "3", label: "C" }
    ];

    // FIXME: Em geral, o front dos formulários não batem com os do
    // Figma, pelo menos não sem fazer as modificações inline, em especial
    // borda e o radius de 2px, e a cor do placeholder.
    const inputSelectStyle = {
        control: (provided: React.CSSProperties) => ({
            ...provided,
            borderRadius: "2px",
            border: "2px solid var(--secondary)"
        }),
        placeholder: (provided: React.CSSProperties) => ({
            ...provided,
            color: "#141414"
        })
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Banco de Recursos</span>
                <span>Recursos</span>
            </Breadcrumb>
            <Title>Recursos</Title>
            <SubTitle>
                {mode === "new" ? "Inserir Novo Recurso" : "Editar Recurso"}
            </SubTitle>

            <FormGroup>
                <Label htmlFor="resource-title">Título:</Label>
                <Input
                    id="resource-title"
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Insira o Título..."
                />
            </FormGroup>

            <FormGroup
                style={{
                    display: "flex",
                    alignItems: "flex-start"
                }}
            >
                <Label htmlFor="resource-description">Descrição:</Label>
                <InputTextarea
                    style={{ border: "2px solid var(--secondary)" }}
                    height="227px"
                    id="resource-description"
                    name="description"
                    placeholder="Insira a Descrição..."
                    value={description}
                    maxLength={9999}
                    onChange={handleDescriptionChange}
                />
            </FormGroup>

            <FormGroup style={{ marginTop: "-15px" }}>
                <Label htmlFor="type">Tipo:</Label>
                <Select
                    inputId="type"
                    name="type"
                    options={resourceTypes}
                    placeholder="Selecione o Tipo, ou marque a caixa abaixo para criar um novo..."
                    value={
                        resourceTypes.find((option) => option.value === type) ||
                        null
                    }
                    onChange={(selectedOption) => {
                        setType(selectedOption?.value || "");
                    }}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            width: "100%"
                        }),
                        control: (provided) => ({
                            ...provided,
                            width: "100%",
                            border: "2px solid var(--secondary)",
                            borderRadius: "0",
                            boxShadow: "none",
                            "&:hover": {
                                borderColor: "var(--secondary)"
                            }
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: "var(--labelText)",
                            opacity: 1
                        })
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label
                    style={{ marginRight: "20px" }}
                    htmlFor="new-type-checkbox"
                >
                    Novo Tipo?
                </Label>
                <input
                    type="checkbox"
                    id="new-type-checkbox"
                    checked={isNewTypeChecked}
                    onChange={handleCheckboxChange}
                />

                {isNewTypeChecked && (
                    <Input
                        id="new-type"
                        type="text"
                        placeholder="Insira o Novo Tipo..."
                        value={newType}
                        onChange={handleNewTypeChange}
                        style={{
                            marginLeft: "20px",
                            border: "2px solid  var(--secondary)"
                        }}
                    />
                )}
            </FormGroup>

            <FormGroup>
                <TableContainer>
                    <LeftColumn>
                        <p style={{ fontWeight: "700" }}>Associação:</p>
                    </LeftColumn>
                    <RightColumn>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o Curso..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o Módulo..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a Atividade..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a Comunicação..."
                            options={courseModules}
                            selectStyles={inputSelectStyle}
                        />
                        <FormGroup>
                            <Label
                                style={{ fontWeight: "400" }}
                                htmlFor="notice"
                            >
                                Aviso:
                            </Label>
                            <Input
                                className="custom-input"
                                id="notice"
                                type="text"
                                placeholder="Aviso..."
                            />
                        </FormGroup>
                    </RightColumn>
                </TableContainer>
            </FormGroup>

            <AttachmentsWrapper>
                <div style={{ fontWeight: "700", marginTop: "2em" }}>
                    <FileUpload
                        onFileUpload={handleFileUpload}
                        showFileList={false}
                    />
                </div>
                <ProgressBarWrapper style={{ marginTop: "0" }}>
                    <ProgressBar
                        currentUsage={currentUsage}
                        totalUsage={totalUsage}
                    />
                </ProgressBarWrapper>
            </AttachmentsWrapper>

            {/* Exibe os anexos carregados fora do wrapper */}
            <div style={{ marginTop: "20px" }}>
                <FileList>
                    {uploadedFiles.map((file, index) => (
                        <FileItem key={index}>
                            <FileNumber>{index + 1}.</FileNumber> {file.name}
                            <FileRemove
                                onClick={() => {
                                    const newFiles = uploadedFiles.filter(
                                        (_, i) => i !== index
                                    );
                                    setUploadedFiles(newFiles);
                                }}
                            >
                                <FaTrash />
                            </FileRemove>
                        </FileItem>
                    ))}
                </FileList>
            </div>

            <Buttons>
                <Button type="submit" btnTheme="primary" onClick={handleSave}>
                    Salvar
                </Button>
                <Button btnTheme="primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </Buttons>
        </Container>
    );
};

const FileList = styled.ul`
    margin-top: 10px;
    list-style: none;
`;

const FileItem = styled.li`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: var(--primary);
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    width: 100%;
`;

const FileNumber = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

const FileRemove = styled.div`
    cursor: pointer;
    margin-left: auto;
    color: var(--secondary);

    &:hover {
        color: var(--gray2);
    }
`;

export default ResourceForm;
