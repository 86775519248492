// Barra de progresso

// ---------------------------------------
// Você está utilizando 47,6 MB de 100 MB
// (47,8%) [======       ]
// ---------------------------------------

import React from "react";
import {
    Container,
    LabelText,
    ProgressBarWrapper,
    PercentageText,
    ProgressBarContainer,
    ProgressBarFill
} from "./styles";

interface ProgressBarProps {
    currentUsage: number;
    totalUsage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
    currentUsage,
    totalUsage
}) => {
    const percentage = (currentUsage / totalUsage) * 100;

    return (
        <Container>
            <LabelText>
                Você está utilizando {currentUsage.toFixed(1)} MB de{" "}
                {totalUsage} MB
            </LabelText>
            <ProgressBarWrapper>
                <PercentageText>({percentage.toFixed(1)}%)</PercentageText>
                <ProgressBarContainer>
                    <ProgressBarFill percentage={percentage} />
                </ProgressBarContainer>
            </ProgressBarWrapper>
        </Container>
    );
};

export default ProgressBar;
