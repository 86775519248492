import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const SearchInputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-left: 10px;
    flex-grow: 1;
`;

export const SearchIcon = styled.div`
    position: absolute;
    top: 55%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--gray2);
`;

export const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-left: 10px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 8px 8px 8px 35px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;
`;

export const AlphabetContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const LetterBox = styled.div<{ isSelected: boolean }>`
    width: 31px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 12px 10px;
    font-weight: bold;

    background-color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.bgPrimary : "var(--gray2)"};

    border: ${({ isSelected, theme }) =>
        isSelected ? `4px solid var(--secondary)` : "none"};

    color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.labelText : "white"};

    &:hover {
        background-color: ${({ theme }) => theme.colors.bgPrimary};
        border: 4px solid var(--secondary);
        color: ${({ theme }) => theme.colors.labelText};
    }
`;

export const GlossaryContainer = styled.div`
    margin-top: 20px;
    text-align: left;
`;

export const GlossaryBlock = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
`;

export const BlockLetter = styled.div`
    font-size: 32px;
    font-weight: 700;
    width: 51px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    line-height: 39px;
    color: white;
    background-color: var(--gray2);
    padding: 8px;
`;

export const GlossaryEntry = styled.div`
    padding: 0;
`;

export const EntryName = styled.div`
    font-weight: bold;
    text-align: left;
    margin-bottom: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const EntryDefinition = styled.div`
    text-align: left;
    margin-bottom: 1em;
`;

export const IconsContainer = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
`;

export const PopupContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: -2em;
    margin-bottom: 1em;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const CommentDelete = styled.div`
    flex: 1;
    text-align: right;
    cursor: pointer;
    margin-left: auto;
    color: var(--secondary);

    &:hover {
        color: var(--gray2);
    }
`;

export const Separator = styled.div`
    border: 2px solid var(--gray2);
    margin: 10px 0;
`;

export const NoMatchMessage = styled.div`
    color: var(--secondary);
    font-size: 18px;
`;

// GlossaryEntry

export const SubTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.titleText};
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
    }
`;

export const EditorFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    width: 100%;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
        margin-bottom: 5px;
    }

    .editor {
        width: 100%;
    }
`;

export const TableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const LeftColumn = styled.div`
    flex: 1;
    margin-top: 8em;

    @media (max-width: 768px) {
        margin-top: 2em;
    }
`;

export const RightColumn = styled.div`
    flex: 1;
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10px;
    text-align: right;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start;
    column-gap: 32px;
    margin-top: 1em;
`;
