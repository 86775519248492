import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
    }
`;

export const Input = styled.input`
    padding: 8px;
    margin-left: 10px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }
`;

export const ForumPageContainer = styled.div`
    padding: 20px;
`;

export const ForumPostContainer = styled.div`
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
`;

export const CommentsContainer = styled.div`
    margin-top: 10px;
`;

export const ForumCommentContainer = styled.div`
    border-top: 1px solid #eee;
    padding: 5px;
`;

export const Text = styled.div`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 16px;
    line-height: 1.2;
    font-weight: bold;
`;

export const Content = styled.div`
    margin-top: 2em;
    flex-grow: 1;
`;

export const TableRow = styled.div`
    padding: 10px;
    border-bottom: 2px solid var(--gray2);
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colors.bgTopTabs};
    }
`;

export const TableHeader = styled.div`
    background-color: var(--gray2);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
`;

export const TableCell = styled.div`
    flex: 1;
    padding: 0 10px;
    padding-bottom: 10px;

    &:first-child {
        flex: 6;
        text-align: left;
    }

    &:nth-child(n + 2) {
        flex: 1;
    }

    > div {
        font-weight: bold;
    }

    > strong {
        font-weight: bold;
    }
`;

export const TitleCell = styled(TableCell)`
    text-align: left;
    font-weight: bold;
`;



export const AuthorDateCell = styled(TableCell)`
    text-align: left;
    font-weight: bold;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const AuthorDate = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;

    > *:first-child {
        margin-right: 20px;
    }

    > span {
        span {
            font-size: 14px;
        }
    }
`;

export const TableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const LeftColumn = styled.div`
    flex: 1;

    @media (max-width: 768px) {
        margin-top: 2em;
    }
`;

export const RightColumn = styled.div`
    flex: 1;
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10px;
    text-align: right;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start;
    column-gap: 32px;
`;

export const Button = styled.div`
    font-size: 16px;
`;

export const Header = styled.div`
    display: table;
    width: 100%;
    background-color: var(--gray2);
    color: white;
`;

export const HoverPopupWrapper = styled.div`
    margin-top: 0.75em;
`;

// Campo de busca

export const SearchInput = styled.input`
    width: 100%;
    padding: 8px 8px 8px 35px;
    font-size: 16px;
    border: 2px solid var(--secondary);
`;

export const SearchIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--gray2);
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const SearchInputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-left: 40px;
    flex-grow: 1;
`;
