import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin-left: 0;

    @media (min-width: 768px) {
        width: 230px;
        margin-left: auto;
    }
`;

export const LabelText = styled.p`
    margin: 0 0 5px;
    font-size: 12px;
    padding: 4px;
`;

export const ProgressBarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const PercentageText = styled.span`
    margin-right: 8px;
    margin-left: 4px;
`;

export const ProgressBarContainer = styled.div`
    width: 100%;
    border: 1px solid;
    border-radius: 2px;
    overflow: hidden;
`;

export const ProgressBarFill = styled.div<{ percentage: number }>`
    width: ${({ percentage }) => `${percentage}%`};
    background-color: #f34f4f;
    height: 18px;
    transition: width 0.3s ease;
`;
