import React from "react";
import ActivityPage from "../ActivityPage";

const Quiz = () => {
    return (
        <ActivityPage
            activityType="Teste Online"
            dataKey="quizes"
            user="Patrícia Zudio"
            announcement="Você ainda tem que realizar o teste online!"
        />
    );
};

export default Quiz;
