import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 64px;

    > div {
        width: 100%;

        h1 {
            margin: 20px 0;
        }



        pre {
            display: inherit;
            white-space: pre-wrap;
            word-break: break-word;
            text-align: initial;
            overflow: auto;
            min-height: 100px;
            margin-top: 40px;
        }
        
        section {
            display: flex;
            column-gap: 160px;

            @media (max-width: 992px) {
                flex-direction: column;
            }
        }
    }

    @media (max-width: 992px) {
        flex-direction: column-reverse;
        row-gap: 40px;
    }

    aside {
        width: 236px;
        min-width: 236px;
        max-width: 236px;
        
        h4 {
            padding: 16px;
            background-color: ${props => props.theme.colors.secondary};
            color: ${props => props.theme.colors.white};
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            margin: 0;
        }

        ul {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 32px;
            background-color: ${props => props.theme.colors.bgTopTabs};

            li {
                display: inherit;
                cursor: pointer;

                a {
                    font-weight: bold;
                    user-select: none;
                    text-align: start;
                }
            }
        }
    }
`;

export const Text = styled.div`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 2em;
    line-height: 1.2;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;
