import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;

    h1 {
        margin: 0 0 30px;
    }
   
`;

export const Content = styled.div`
    margin-top: 2em;
    flex-grow: 1;
`;

export const TableRow = styled.div`
    padding: 10px;
    border-bottom: 2px solid var(--gray2);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;

    &:hover {
        background-color: ${(props) => props.theme.colors.bgTopTabs};
    }
`;

export const TableCell = styled.div`
    display: flex;
    flex: 1;
    padding: 10px;
    padding-bottom: 10px;

    &:first-child {
        flex: 5;
        text-align: left;
        align-items: center;
    }

    &:nth-child(n + 2) {
        flex: 1;
        justify-content: center;
    }

    input {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start;
    column-gap: 2px;
`;

// Campo de busca

export const SearchInput = styled.input`
    width: 100%;
    height: 40px;
    padding: 8px 8px 8px 35px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    margin-top: -40px;
`;

export const SearchIcon = styled.div`
    transform: translateY(-50%);
    color: var(--gray2);
    margin-top: 21px;
    margin-left: 15px;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
        height: 40px;
        margin: 0 5px;
    }

    .active {
        background: rgba(91, 12, 15, 0.2);
        color: #000;
    }
`;

export const SearchContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    margin-right: 40px;
`;

export const SearchInputContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin-left: 10px;
    margin-top: 40px;
    flex-grow: 1;
`;

export const Infos = styled.div`
    padding: 0 100px;
    display: flex;
    justify-content: center;
    align-items: start;
`;

export const ImageTextContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 16px;
    margin-top: 20px;
    width: 700px;
}
`;

export const ProfileImage = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 10000px;
    object-fit: cover;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: baseline;
`;