import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    border: 2px solid var(--gray3);

    /* Reseta as bordas */
    .ql-container,
    .ql-editor,
    .ql-toolbar {
        border: none !important;
    }

    .ql-editor {
        min-height: 250px;
        font-size: 16px;
        border-radius: 0px 0px 2px 2px;
        border: 2px solid;
    }

    .ql-toolbar {
        text-align: left;
        background-color: white;
        border-bottom: 2px solid var(--gray3) !important;
    }

    .ql-editor strong {
        font-weight: bold;
    }

    .ql-editor em {
        font-style: italic;
    }
`;
