import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import Title from "../../../components/Title";
import { FaSearch, FaPencilAlt, FaTrash } from "react-icons/fa";
import HoverPopup from "../../../components/HoverPopup";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import {
    Container,
    SearchContainer,
    SearchInputContainer,
    SearchInput,
    SearchIcon,
    AlphabetContainer,
    LetterBox,
    GlossaryContainer,
    GlossaryBlock,
    BlockLetter,
    GlossaryEntry,
    EntryName,
    EntryDefinition,
    IconsContainer,
    PopupContainer,
    CommentDelete,
    Pagination,
    Separator,
    NoMatchMessage
} from "./styles";

type GlossaryEntryType = {
    name: string;
    definition: string;
};

const Glossary = () => {
    const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
    const navigate = useNavigate();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const { codigo } = useParams<{ codigo: string }>();
    const courseId = codigo;
    const { openConfirmDialog, ConfirmDialog } = useConfirmDialog();

    const sampleText =
        "Fringilla est ullamcorper eget nulla facilisi etiam dignissim. Maecenas sed enim ut sem viverra aliquet eget. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus? Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec hendrerit tempor tellus. Donec pretium posuere tellus. Proin quam nisl, tincidunt et, mattis eget, convallis nec, purus.";

    const glossaryData = {
        entries: [
            {
                name: "Aprendizagem",
                definition: sampleText
            },
            {
                name: "Cerebro",
                definition: sampleText
            },
            {
                name: "Conhecimento",
                definition: sampleText
            },
            {
                name: "Desenho",
                definition: sampleText
            },
            {
                name: "Diagrama",
                definition: sampleText
            },
            {
                name: "Essencial",
                definition: sampleText
            }
        ]
    };

    const groupedEntries = glossaryData.entries.reduce<
        Record<string, GlossaryEntryType[]>
    >((acc, entry) => {
        const firstLetter = entry.name[0].toUpperCase();
        if (!acc[firstLetter]) {
            acc[firstLetter] = [];
        }
        acc[firstLetter].push(entry);
        return acc;
    }, {});

    const handleLetterClick = (letter: string) => {
        setSelectedLetter(letter === selectedLetter ? null : letter);
    };

    const handleInsertClick = () => {
        navigate(`/courseenvironment/${courseId}/query/glossary/new`);
    };

    const handleEditClick = (entry: GlossaryEntryType) => {
        navigate(
            `/courseenvironment/${courseId}/query/glossary/edit/${entry.name.toLowerCase()}`,
            {
                state: { entry }
            }
        );
    };

    const handleDeleteClick = (entry: GlossaryEntryType) => {
        openConfirmDialog({
            text: `Você tem certeza que deseja excluir o termo "${entry.name}"?`,
            title: "Excluir termo",
            onConfirm: () => {
                console.log("Deletando...", entry.name);
            }
        });
    };

    const filteredEntries = selectedLetter
        ? { [selectedLetter]: groupedEntries[selectedLetter] }
        : groupedEntries;

    const noMatches =
        selectedLetter &&
        (!filteredEntries[selectedLetter] ||
            filteredEntries[selectedLetter].length === 0);

    return (
        <Container>
            <Breadcrumb>
                <span>Curso: Nome do Curso</span>
                <span>Consulta</span>
                <span>Glossário</span>
            </Breadcrumb>
            <Title>Glossário</Title>

            <SearchContainer>
                <Button btnTheme="primary" onClick={handleInsertClick}>
                    Inserir
                </Button>
                <SearchInputContainer>
                    <SearchIcon>
                        <FaSearch />
                    </SearchIcon>
                    <SearchInput type="text" placeholder="Buscar Termo" />
                </SearchInputContainer>
            </SearchContainer>

            <AlphabetContainer>
                {alphabet.map((letter) => (
                    <LetterBox
                        key={letter}
                        onClick={() => handleLetterClick(letter)}
                        isSelected={selectedLetter === letter}
                    >
                        {letter}
                    </LetterBox>
                ))}
            </AlphabetContainer>

            <GlossaryContainer>
                {noMatches ? (
                    <NoMatchMessage>
                        Nenhum termo encontrado com {selectedLetter}
                    </NoMatchMessage>
                ) : (
                    Object.keys(filteredEntries)
                        .sort()
                        .map((letter) => (
                            <React.Fragment key={letter}>
                                <GlossaryBlock>
                                    <BlockLetter>{letter}</BlockLetter>
                                    <div>
                                        {filteredEntries[letter]?.map(
                                            (entry, index) => (
                                                <GlossaryEntry key={index}>
                                                    <EntryName>
                                                        {entry.name}
                                                        <IconsContainer>
                                                            <FaPencilAlt
                                                                onClick={() =>
                                                                    handleEditClick(
                                                                        entry
                                                                    )
                                                                }
                                                            />
                                                            <CommentDelete>
                                                                <FaTrash
                                                                    onClick={() =>
                                                                        handleDeleteClick(
                                                                            entry
                                                                        )
                                                                    }
                                                                />
                                                            </CommentDelete>
                                                        </IconsContainer>
                                                    </EntryName>
                                                    <PopupContainer>
                                                        <HoverPopup linkText="Associações" />
                                                    </PopupContainer>
                                                    <EntryDefinition>
                                                        {entry.definition}
                                                    </EntryDefinition>
                                                </GlossaryEntry>
                                            )
                                        )}
                                    </div>
                                </GlossaryBlock>
                                <Separator />
                            </React.Fragment>
                        ))
                )}
            </GlossaryContainer>
            <Pagination>
                <span>Página</span>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href="#"
                    style={{ color: "white", backgroundColor: "#949494" }}
                >
                    &lt;
                </a>
                <span>01</span>
                <span>de</span>
                <span>01</span>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href="#"
                    style={{ color: "white", backgroundColor: "#F34F4F" }}
                >
                    &gt;
                </a>
            </Pagination>
            <ConfirmDialog />
        </Container>
    );
};

export default Glossary;
