import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    margin-top: 2em;
    flex-grow: 1;
`;

export const Text = styled.div`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 2em;
    line-height: 1.2;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const AnnouncementContainer = styled.div`
    border-bottom: 2px solid var(--gray2);
    margin-bottom: 2em;
    cursor: pointer;
`;

export const AnnouncementHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    font-weight: bold;
`;

export const HeaderItem = styled.div`
    flex: 1;
    text-align: left;

    &:not(:last-child) {
        margin-right: 10px;
    }
`;

export const InsertButton = styled.div`
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-start;

    button {
        padding: 10px 20px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 10px;
    }

    svg:last-child {
        margin-right: 0;
    }
`;

export const CommentDelete = styled.div`
    cursor: pointer;
    color: var(--secondary);

    &:hover {
        color: var(--gray2);
    }
`;

// Announcement

export const Input = styled.input`
    padding: 8px;
    margin-left: 10px;
    font-size: 16px;
    border: 2px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;

    ::placeholder {
        color: var(--labelText);
        opacity: 1;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
    }
`;

export const EditorFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    width: 100%;
`;

export const TableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const LeftColumn = styled.div`
    flex: 1;
    margin-top: 8em;

    @media (max-width: 768px) {
        margin-top: 2em;
    }
`;

export const RightColumn = styled.div`
    flex: 1;
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10px;
    text-align: right;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start;
    column-gap: 32px;
    margin-top: 20px;
`;
