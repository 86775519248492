import styled from "styled-components";
import Button from "../Button";

export const Container = styled.div<{ position: "left" | "center" | "right" }>`
    text-align: ${({ position }) => position};
`;

export const PopupWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

export const PopupContainer = styled.div`
    position: absolute;
    margin-top: -25px;
    right: 0;
    padding: 8px;
    background: #720e12;
    background: ${(props) => (props.theme.id === 1 ? "#720E12" : "#fff")};

    border-radius: 2px;
    line-height: 2;
    z-index: 1;

    & > .inner {
        background-color: ${(props) => props.theme.colors.bgPrimary};
        padding: 10px;
        border-radius: 2px;
        width: 240px;

        p {
            &:first-child {
                margin-bottom: 40px;
            }
        }
    }

    .indented {
        padding-left: 20px;
    }

    strong {
        font-weight: bold;
        color: #720e12;
        float: right;
    }
`;

export const LinkButton = styled(Button)<{ bold: boolean }>`
    font-size: 16px;
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
